import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Badge,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  Link,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Image from "mui-image";
import { cyan } from "@mui/material/colors";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import SapatoDemocrata from "../../assets/SapatoDemocrata2.png";
import logoDemocrata from "../../assets/logo-democrata.png";
import { authenticationApi } from "../../services/api";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import { Imagem } from "../SignIn/styles";

const SignUp = (props) => {
  const history = useHistory();

  const [formFields, setFormFields] = useState({
    username: "",
    password: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [isSendingRequest, setIsSendingRequest] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const isMobile = useBreakpoints("md");

  const handleChangeFields = (e) => {
    setFormFields({ ...formFields, [e.target.name]: e.target.value });
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    if (formFields.password !== formFields.confirmPassword) {
      toast.error("As senhas não conferem");
      return;
    }

    if (formFields.username === "" || formFields.password === "") {
      toast.error("Preencha todos os campos para continuar");
      return;
    }

    try {
      setIsSendingRequest(true);

      const response = await authenticationApi.post(`/auth/criar-senha`, {
        usuario: formFields.username,
        senha: formFields.password,
        senhaRpt: formFields.confirmPassword,
        sistema: 1,
      });

      if (response.status === 200) {
        toast.success("Solicitação enviada com sucesso");

        setTimeout(() => {
          history.push("/");
        }, 2000);
      } else {
        throw new Error("Houve um problema ao solicitar acesso");
      }
    } catch (error) {
      toast.error(
        error.response.data.userMessage || "Erro ao solicitar acesso"
      );
    } finally {
      setIsSendingRequest(false);
    }
  };

  return (
    <>
      <ToastContainer />

      <div className="d-lg-flex half">
        <Hidden mdDown>
          <div className="bg order-1 order-md-2">
            <Imagem src={SapatoDemocrata} alt="Logo Democrata" />
          </div>
        </Hidden>

        <div className="contents order-2 order-md-1">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-7">
                <div className="text-center mb-4">
                  <img
                    src={logoDemocrata}
                    alt="Logo"
                    style={{ margin: "auto", width: "100%" }}
                  />
                </div>

                <form className="form" onSubmit={handleSignUp}>
                  <div className="form-group first">
                    <label htmlFor="username">Usuário</label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        name="username"
                        className="form-control"
                        placeholder="Usuário Democrata"
                        value={formFields.username}
                        onChange={handleChangeFields}
                      />
                    </div>
                  </div>
                  <div className="form-group last mb-3">
                    <label htmlFor="password">Senha</label>
                    <div className="input-group mb-3">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        name="password"
                        placeholder="Senha"
                        value={formFields.password}
                        onChange={handleChangeFields}
                      />

                      <div className="input-group-append">
                        <div
                          className="input-group-text"
                          style={{ background: "#ffff", border: "none" }}
                        >
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <label htmlFor="confirmPassword">Confirmar senha</label>
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      name="confirmPassword"
                      placeholder="Confirmar senha"
                      value={formFields.confirmPassword}
                      onChange={handleChangeFields}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      variant="contained"
                      fullWidth={isMobile}
                      disabled={isSendingRequest}
                      size="large"
                      sx={{ backgroundColor: cyan[800] }}
                      type="submit"
                    >
                      Solicitar acesso
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(SignUp);
