import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";

import { cyan } from "@mui/material/colors";
import { ExpirationDatePicker } from "./ExpirationDatePicker";
import { useState } from "react";

import { authenticationApi } from "../../services/api";
import { useMutation, useQueryClient } from "react-query";
import { formatarData } from "../../utils/utils";
import { DocumentDialog } from "./DocumentDialog";

export const UserApprovalDialog = ({ user, open, handleDialogIsOpen }) => {
  const queryClient = useQueryClient();

  const [isEditing, setIsEditing] = useState(false);

  const [showDocumentField, setShowDocumentField] = useState(false);
  const [documentValue, setDocumentValue] = useState("");

  const handleShowDocumentField = () => {
    setShowDocumentField(!showDocumentField);
  };

  const handleChangeDocument = (event) => {
    setDocumentValue(event.target.value);
  };

  const currentUserStorage = JSON.parse(localStorage.getItem("@Express:User"));

  const [currentUserSettings, setCurrentUserSettings] = useState({
    currentExpirationDate: user.expira,
    isAdmin: user.codUsuario?.privilegios?.subnvlPrivilegio === 3,
    role: user.codUsuario.tipo,
    status: user.status === 1,
  });

  const handleChangeCurrentExpirationDate = (date) => {
    setCurrentUserSettings({
      ...currentUserSettings,
      currentExpirationDate: date,
    });
  };

  const mutation = useMutation(
    async () => {
      let userUpdate = {
        privilegios: currentUserSettings.isAdmin ? 3 : 1,
        tipo: currentUserSettings.role,
      };

      if (documentValue) {
        if (currentUserSettings.role === "R") {
          userUpdate.representante = documentValue;
        }

        if (currentUserSettings.role === "C") {
          userUpdate.consultor = documentValue;
        }

        if (currentUserSettings.role === "L") {
          userUpdate.vendedor = documentValue;
        }

        if (currentUserSettings.role === "V") {
          userUpdate.vendedor = documentValue;
        }
      }

      const [authResponse, roleResponse] = await Promise.all([
        authenticationApi.put(`/autorizacao/${user.id}`, {
          status: currentUserSettings.status ? 1 : 0,
          expira: currentUserSettings.currentExpirationDate,
        }),
        authenticationApi.put(`/usuario/${user.codUsuario.id}`, userUpdate),
      ]);

      return { authResponse, roleResponse };
    },
    {
      onMutate: (updatedUserSettings) => {
        setIsEditing(true);

        queryClient.setQueryData(
          ["userWaitingApproval", user.id],
          updatedUserSettings
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["userWaitingApproval"] });

        setIsEditing(false);

        handleDialogIsOpen();
      },
      onError: (error) => {
        console.log(error);

        setIsEditing(false);
      },
    }
  );

  const handleChangeRole = (event) => {
    setCurrentUserSettings({
      ...currentUserSettings,
      role: event.target.value,
    });
  };

  const handleSaveSettings = () => {
    mutation.mutate({
      status: currentUserSettings.status ? 1 : 0,
      expira: currentUserSettings.currentExpirationDate,
    });

    currentUserStorage.usuario.toUpperCase() ===
      user.codUsuario.nome.toUpperCase() &&
      sessionStorage.setItem(
        "@Express:User",
        JSON.stringify({
          ...currentUserStorage,
          expira: currentUserSettings.currentExpirationDate,
          tipo: currentUserSettings.role,
          privilegios: currentUserSettings.isAdmin ? 3 : 1,
          status: currentUserSettings.status ? 1 : 0,
        })
      );
  };

  const dialogFields = {
    primary: [
      {
        label: "Usuário",
        value: (
          <Typography variant="subtitle2">{user.codUsuario?.nome}</Typography>
        ),
      },
      {
        label: "Data Solicitação",
        value: (
          <Typography variant="subtitle2">
            {formatarData(user.dtSolicitacao)}
          </Typography>
        ),
      },
      {
        label: "Tipo de Usuário",
        value: (
          <Box
            sx={{ minWidth: "100%", display: "flex", flexDirection: "column" }}
          >
            <FormControl fullWidth size="small">
              <InputLabel id="role">Tipo</InputLabel>
              <Select
                labelId="role"
                label="Tipo"
                value={currentUserSettings.role || 0}
                onChange={handleChangeRole}
              >
                <MenuItem value={0}>Selecione</MenuItem>
                <MenuItem value="R">Representante</MenuItem>
                <MenuItem value="C">Consultor</MenuItem>
                <MenuItem value="L">Licenciada</MenuItem>
                <MenuItem value="V">Vendedor</MenuItem>
              </Select>
            </FormControl>

            <Button
              variant="text"
              size="small"
              alignSelf="flex-end"
              onClick={handleShowDocumentField}
            >
              Vincular CNPJ ou CPF
            </Button>

            {showDocumentField && (
              <DocumentDialog
                open={showDocumentField}
                onClose={handleShowDocumentField}
                documentValue={documentValue}
                handleChangeDocument={handleChangeDocument}
              />
            )}
          </Box>
        ),
      },
    ],
    secondary: [
      {
        label: "Status",
        value: (
          <Switch
            checked={currentUserSettings.status}
            onClick={() =>
              setCurrentUserSettings({
                ...currentUserSettings,
                status: !currentUserSettings.status,
              })
            }
          />
        ),
      },
      {
        label: "Administrador",
        value: (
          <Switch
            checked={currentUserSettings.isAdmin}
            onClick={() =>
              setCurrentUserSettings({
                ...currentUserSettings,
                isAdmin: !currentUserSettings.isAdmin,
              })
            }
          />
        ),
      },
      {
        label: "Data Expiração",
        value: (
          <ExpirationDatePicker
            label=""
            currentDate={currentUserSettings.currentExpirationDate}
            handleDateChange={handleChangeCurrentExpirationDate}
          />
        ),
      },
    ],
  };

  return (
    <Dialog open={open} onClose={handleDialogIsOpen}>
      <DialogTitle>Configuração</DialogTitle>

      <DialogContent width="100%">
        {dialogFields.primary.map((field) => (
          <Box
            key={field.label}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              my: 4,
            }}
          >
            <Box minWidth={200}>
              <Typography variant="subtitle2">{field.label}</Typography>
            </Box>
            <Box maxWidth={200}>{field.value}</Box>
          </Box>
        ))}

        <Divider />

        {dialogFields.secondary.map((field) => (
          <Box
            key={field.label}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              my: 4,
            }}
          >
            <Box minWidth={200}>
              <Typography variant="subtitle2">{field.label}</Typography>
            </Box>
            <Box maxWidth={200}>{field.value}</Box>
          </Box>
        ))}

        <Button
          variant="contained"
          onClick={handleSaveSettings}
          disabled={isEditing}
          sx={{
            backgroundColor: !isEditing && cyan[800],
            "&:hover": {
              backgroundColor: cyan[900],
            },
          }}
          fullWidth
        >
          {isEditing ? "Salvando..." : "Salvar"}
        </Button>
      </DialogContent>
    </Dialog>
  );
};
