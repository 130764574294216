import React from "react";
import { Box, Typography, Tooltip, Grid, Divider } from "@mui/material";
import StoreIcon from "@mui/icons-material/Store";
import { grey } from "@mui/material/colors";
import { useBreakpoints } from "../hooks/useBreakpoints";
import { documentMask } from "../utils/documentMask";

export const PageAndCustomerInfo = ({ sectionTitle, disableCustomer }) => {
  const isMobile = useBreakpoints("md");
  const isSmallScreen = useBreakpoints("sm");
  const customerData = JSON.parse(localStorage.getItem("@CLIENTE")) || {
    cliente: {},
  };

  const currentPathName = window.location.pathname;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: isMobile ? "column" : "row",
        width: "100%",
        mt: 2,
        mb: 1,
      }}
    >
      <Typography
        variant={"h4"}
        fontWeight={700}
        textAlign={"center"}
        color={grey[700]}
        mb={2}
      >
        {sectionTitle}
      </Typography>

      {!disableCustomer && currentPathName !== "/lista/produtos" && (
        <Box
          sx={{
            display: isMobile ? "grid" : "flex",
            gridTemplateColumns: customerData.cliente.fantasia
              ? "1fr 1fr 1fr"
              : "1fr 1fr",

            gap: 2,
          }}
          alignItems="center"
        >
          {customerData.cliente.fantasia && (
            <Grid item>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  maxWidth: 150,
                }}
              >
                <StoreIcon sx={{ mr: 1 }} />
                <Tooltip
                  title={customerData.cliente.fantasia || ""}
                  arrow
                  placement="top"
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {customerData.cliente.fantasia || ""}
                  </Typography>
                </Tooltip>
              </Box>
            </Grid>
          )}

          <Grid item>
            <Tooltip
              title={`CNPJ: ${documentMask(
                customerData.cliente.cnpjCpf || ""
              )}`}
              placement="top"
              arrow
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body2"
                  textAlign={"center"}
                  color={grey[700]}
                >
                  {customerData.cliente.razao}
                </Typography>
              </Box>
            </Tooltip>
          </Grid>

          <Grid item>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="overline"
                textAlign={"center"}
                lineHeight={1.1}
              >
                {customerData.cliente.endereco}
              </Typography>
            </Box>
          </Grid>
        </Box>
      )}
    </Box>
  );
};
