import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { VariableSizeList } from "react-window";
import { useQuery } from "react-query";
import { useLoggedUser } from "../../../../contexts/LoggedUserContext";
import { useClienteContext } from "../../../../contexts/ClienteContext";
import { useDebounce } from "../../../../hooks/useDebounce";
import { CustomerCard } from "./CustomerCard";
import { Place } from "@mui/icons-material";

import api from "../../../../services/api";
import { getClientes } from "../services/customersService";

export const CustomersList = () => {
  const { loggedUser } = useLoggedUser();
  const { selecionarCliente } = useClienteContext();

  const [selectedEstado, setSelectedEstado] = useState(null);
  const [filterValue, setFilterValue] = useState("");
  const [selectedCidade, setSelectedCidade] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const currentUserType = useMemo(() => {
    if (loggedUser.representante) {
      return { type: "representante", id: loggedUser.representante };
    }
    if (loggedUser.consultor) {
      return { type: "consultor", id: loggedUser.consultor };
    }
    return { type: "vendedor", id: loggedUser.vendedor };
  }, [loggedUser]);

  const debouncedCustomerSearch = useDebounce(filterValue, 500);

  const getEstado = async () => {
    const response = await api.get(
      `clientes/consulta/uf/${currentUserType.id}`
    );
    return response.data.records;
  };

  const getCidade = async () => {
    const response = await api.get(
      `clientes/consulta/cidade/${currentUserType.id}?uf=${selectedEstado}`
    );
    return response.data.records;
  };

  const { data: estados, isLoading: isLoadingStates } = useQuery(
    "fetchEstados",
    getEstado,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: cidades, isLoading: isLoadingCities } = useQuery(
    ["fetchCidades", selectedEstado],
    getCidade,
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedEstado,
    }
  );

  const { data: customersRequest, isLoading: isLoadingCustomers } = useQuery(
    [
      "fetchClientes",
      selectedCidade,
      selectedEstado,
      currentUserType,
      debouncedCustomerSearch,
    ],
    () =>
      getClientes(
        currentUserType.type,
        currentUserType.id,
        selectedEstado,
        selectedCidade,
        filterValue
      ),
    {
      refetchOnWindowFocus: false,
      staleTime: 1000,
    }
  );

  const handleChangeEstado = (event) => {
    setSelectedCidade("");
    setSelectedEstado(event.target.value);
  };

  const handleChangeCidade = (event) => {
    setSelectedCidade(event.target.value);
  };

  const handleFilterChange = (value) => {
    let sanitizedValue = value;
    if (typeof value === "string" && value.length > 20) {
      sanitizedValue = value.slice(0, 20);
    }
    if (!isNaN(value)) {
      sanitizedValue = value.replace(/[^0-9]/g, "").slice(0, 14);
    }
    setSelectedCidade("");
    setSelectedEstado("");
    setFilterValue(sanitizedValue);
  };

  const handleResetCustomerFilter = () => {
    setFilterValue("");
  };

  const handleGuardarDados = (item) => {
    setSelectedItem(item);
    selecionarCliente(item);
    if (item === selectedItem) {
      setSelectedItem("");
    }
  };

  const handleRemoveStateAndCityFilter = () => {
    setSelectedCidade(null);
    setSelectedEstado(null);
  };

  const handleRemoveCityFilter = () => {
    setSelectedCidade(null);
  };

  const handleRemoveAllFilters = () => {
    setSelectedCidade(null);
    setSelectedEstado(null);
    setFilterValue("");
  };

  const getItemSize = (index) => {
    const grupoCidades = customersRequest.customers[index];
    const baseHeight = 50;
    const customerHeight = isMobile ? 120 : 100;
    return baseHeight + grupoCidades.clientes.length * customerHeight;
  };

  const Row = ({ index, style }) => {
    const grupoCidades = customersRequest.customers[index];
    return (
      <Box style={style} key={customersRequest.customers[index].cnpjCpf}>
        <Button
          startIcon={<Place />}
          variant="text"
          color="primary"
          sx={{ mb: 2 }}
        >
          {grupoCidades.cidade}
        </Button>

        {grupoCidades.clientes.map((cliente) => (
          <CustomerCard
            key={cliente.cnpjCpf}
            item={cliente}
            selectedCustomer={selectedItem}
            onChangeCustomer={() => handleGuardarDados(cliente)}
          />
        ))}
      </Box>
    );
  };

  return (
    <div className={`${isMobile ? "" : "container"} w-100`}>
      <Box>
        <Box
          component="form"
          sx={{
            display: "grid",
            gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr 1fr",
            gap: 2,
            alignItems: "center",
            mt: 2,
          }}
          noValidate
          autoComplete="off"
        >
          <FormControl fullWidth={isMobile}>
            <InputLabel id="state-select">Estado</InputLabel>
            <Select
              label="Estado"
              labelId="state-select"
              onChange={handleChangeEstado}
              value={selectedEstado || ""}
            >
              {!isLoadingStates &&
                estados.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl fullWidth={isMobile}>
            <InputLabel id="city-select">Cidade</InputLabel>
            <Select
              label="Cidade"
              labelId="city-select"
              defaultValue="Selecione"
              value={selectedCidade || ""}
              disabled={!selectedEstado}
              onChange={handleChangeCidade}
            >
              {cidades &&
                cidades.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl fullWidth={isMobile}>
            <TextField
              id="filled-search"
              label="CNPJ ou Razão Social"
              type="search"
              variant="outlined"
              value={filterValue}
              onChange={(e) => handleFilterChange(e.target.value)}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            mt: 3,
            mb: 3,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {selectedEstado && (
              <Chip
                label={selectedEstado}
                color="info"
                size="large"
                onDelete={handleRemoveStateAndCityFilter}
              />
            )}
            {selectedCidade && (
              <Chip
                label={selectedCidade}
                color="info"
                size="large"
                onDelete={handleRemoveCityFilter}
              />
            )}
            {filterValue && (
              <Chip
                label={filterValue}
                color="info"
                size="large"
                onDelete={handleResetCustomerFilter}
              />
            )}
            {selectedEstado && selectedCidade && (
              <Typography
                onClick={handleRemoveAllFilters}
                color="error"
                sx={{ cursor: "pointer" }}
              >
                Remover todos os filtros
              </Typography>
            )}
          </Box>
          {customersRequest && (
            <Typography variant="subtitle1">
              Total de Clientes:{" "}
              <strong>{customersRequest.header.recordCount}</strong>
            </Typography>
          )}
        </Box>
        {isLoadingCustomers && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 8,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {!isLoadingCustomers && customersRequest.customers.length === 0 && (
          <Typography variant="subtitle1" mt={2}>
            Nenhum cliente encontrado.
          </Typography>
        )}
        {!isLoadingCustomers && (
          <VariableSizeList
            height={500}
            width="100%"
            itemCount={customersRequest.customers.length}
            itemSize={getItemSize}
          >
            {Row}
          </VariableSizeList>
        )}
      </Box>
    </div>
  );
};
