// Routes.js
import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { isAuthenticated, isAdmin } from "./services/auth";
import SignIn from "./pages/SignIn";
import Home from "./pages/Home/Home";
import NaoEncontrada from "./pages/404/404";

import { ProdutosSemana } from "./pages/Express/Lista/Produtos/ProdutosSemana";
import { ProdutoDetalhe } from "./pages/Express/ProdutoDetalhe/ProdutoDetalhe";
import { Checkout } from "./pages/Express/Checkout/Checkout";
import { ClienteContextWrapper } from "./contexts/ClienteContext";
import Favoritos from "./pages/Express/Favoritos/Favoritos";
import PedidosRealizados from "./pages/Express/PedidosRealizados/PedidosRealizados";

import ProdutosGrade from "./pages/Express/ProdutosGrade/ProdutosGrade";
import { ProductContextWrapper } from "./contexts/ProductContext";
import { TabelasPrecoProvider } from "./contexts/TabelasPreco";
import { SelectedProductsProvider } from "./contexts/SelectedProductsContext";
import SignUp from "./pages/SignUp";
import { UserApproval } from "./pages/Admin/UserApproval";
import { LoggedUserProvider } from "./contexts/LoggedUserContext";
import Customers from "./pages/Express/Clientes/Customers";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() && isAdmin() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: "/lista/produtos", state: { from: props.location } }}
        />
      )
    }
  />
);

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={SignIn} />
      <Route exact path="/cadastro" component={SignUp} />
      <Route exact path="/home" component={Home} />

      <PrivateRoute path="/homeApp" component={() => <h1>App</h1>} />

      <LoggedUserProvider>
        <ClienteContextWrapper>
          <TabelasPrecoProvider>
            <ProductContextWrapper>
              <SelectedProductsProvider>
                <AdminRoute path="/usuarios" component={UserApproval} />
                <PrivateRoute
                  path="/clientes/:referencia?"
                  component={Customers}
                />
                <PrivateRoute
                  path="/lista/produtos"
                  component={ProdutosSemana}
                />
                <PrivateRoute
                  path="/grade/produtos"
                  component={ProdutosGrade}
                />
                <PrivateRoute
                  path="/produto/:referencia"
                  component={ProdutoDetalhe}
                />
                <PrivateRoute path="/favoritos" component={Favoritos} />
                <PrivateRoute path="/checkout" component={Checkout} />
                <PrivateRoute
                  path="/pedidos-realizados"
                  component={PedidosRealizados}
                />
              </SelectedProductsProvider>
            </ProductContextWrapper>
          </TabelasPrecoProvider>
        </ClienteContextWrapper>
      </LoggedUserProvider>

      <Route path="*" component={NaoEncontrada} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
