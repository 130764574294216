import { useQuery, useMutation, useQueryClient } from "react-query";
import api from "../services/api";
import { useLoggedUser } from "../contexts/LoggedUserContext";

export const useFavorites = (product, favorite) => {
  const queryClient = useQueryClient();

  const { loggedUser, currentUser } = useLoggedUser();

  const { data: favorites, isLoading } = useQuery(
    "favorites",
    async () => {
      if (favorite) {
        const response = await api.get(`/favoritos/usuario/${currentUser}`);
        return response.data.records;
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: favorite,
    }
  );

  const isFavorite = favorites?.some(
    (item) => item.referencia === product?.referencia
  );

  const toggleFavoriteMutation = useMutation(
    async () => {
      if (isFavorite) {
        await api.delete(`/favoritos/${product?.referencia}/${currentUser}`);
      } else {
        await api.post("/favoritos", {
          referencia: product?.referencia,
          usuario: currentUser,
        });
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("favorites");
      },
    }
  );

  const toggleFavorite = async () => {
    await toggleFavoriteMutation.mutateAsync();
  };

  return { isFavorite: !!isFavorite, isLoading, toggleFavorite };
};
