import {
  Box,
  Button,
  CircularProgress,
  Link,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { SummaryBox } from "./SummaryBox";
import { useState } from "react";

import api from "../../../../services/api";

import { ProductGroupAccordion } from "./ProductGroupAccordion";

import { ToastContainer, toast } from "react-toastify";
import { EmptyItems } from "../../ProdutosGrade/components/EmptyItemsWarning";

import { validateProductsMinQuantity } from "../validators/productsMinQuantity";
import { useLoggedUser } from "../../../../contexts/LoggedUserContext";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { fetchProdutosClientes } from "../services/fetchProdutosClientes";
import { useCheckout } from "../../../../contexts/CheckoutContext";

export const CheckoutItems = () => {
  const queryClient = useQueryClient();

  const { loggedUser, currentUser } = useLoggedUser();

  const { selectedCondPgto } = useCheckout();

  const { data: customerProducts, isLoading } = useQuery(
    ["checkout", currentUser],
    () => {
      return fetchProdutosClientes(currentUser);
    }
  );

  const [selectedDates, setSelectedDates] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  const [isOrderLoading, setIsOrderLoading] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);

  const [isDiscountApplyed, setIsDiscountApplyed] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Função de selecionar datas e somar os produtos dos clientes.
   *
   * @param {string} selectedDate Recebe a data selecionada no checkbox, e armazena em datasSelecionadas.
   * @param {string} clientId Recebe o id do cliente com base na data, para realizar validações da soma.
   *
   * @const {boolean} [isSelectedForClient] Mapeia as datas selecionadas e os clientes que possuem produtos nesta data.
   * @const {number} [totalSum] Reduce 1 - Acumular em uma data X os produtos dos clientes com base no id dos clientes.
   *                            Reduce 2 - Acumular os precos dos produtose retornar a soma.
   */
  const handleProductCheck = (
    selectedDate,
    clientId,
    razao,
    tabelaPreco,
    isDiscountApplyed
  ) => {
    let updatedDatasSelecionadas = [...selectedDates];

    if (
      updatedDatasSelecionadas.length > 0 &&
      updatedDatasSelecionadas[0].clientId !== clientId
    ) {
      return toast.warning("Finalize os pedidos para o cliente atual.");
    }

    const clientEntry = updatedDatasSelecionadas.find(
      (entry) => entry.clientId === clientId
    );

    if (clientEntry) {
      if (clientEntry.dates.includes(selectedDate)) {
        clientEntry.dates = clientEntry.dates.filter(
          (date) => date !== selectedDate
        );

        if (clientEntry.dates.length === 0) {
          updatedDatasSelecionadas = [];
        }
      } else {
        clientEntry.dates.push(selectedDate);
      }
    } else {
      updatedDatasSelecionadas.push({
        clientId,
        razao,
        tabelaPreco,
        isDiscountApplyed,
        dates: [selectedDate],
      });
    }

    const totalSum = updatedDatasSelecionadas.reduce(
      (accumulator, { clientId }) => {
        const clientProducts = customerProducts.find(
          (cliente) => cliente.cliente.cnpjCpf === clientId
        );
        return accumulator + clientProducts.valorTotal, 0;
      },
      0
    );

    setSelectedDates(updatedDatasSelecionadas);
    setTotalAmount(totalSum);
  };

  const mutation = useMutation(
    async (checkoutData) => {
      const response = await api.post("/checkout/gerar-pedidos", checkoutData);

      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["checkout", currentUser]);
        toast.success("Pedido(s) gerado(s) com sucesso!");
        clearDates();
      },
    }
  );

  const clearDates = () => {
    setSelectedDates([]);
  };

  const handleGerarPedido = async (customer, representant, dates) => {
    if (!loggedUser) {
      return toast.error("Ocorreu um usuário, faça login novamente.");
    }

    const checkoutData = [
      {
        reprVendedor: representant,
        cliente: customer,
        semanas: dates,
        condicao: String(selectedCondPgto),
        usuario: loggedUser.usuario,
      },
    ];

    try {
      await mutation.mutateAsync(checkoutData);

      setTotalAmount(0);

      sessionStorage.removeItem("@Express:SelectedDates");
    } catch (error) {
      if (error.response.status === 409) {
        return toast.warning(error.response.data.mensagem);
      }

      if (error.response.status === 403) {
        return toast.warning("Existem produtos sem grade definida.");
      }

      if (error.response.status === 422) {
        return toast.warning(
          "Existem itens com a validade expirada. Por favor, renove o item ou remova-o do carrinho."
        );
      }

      return toast.error("Erro ao gerar pedido, tente novamente mais tarde.");
    } finally {
      setIsOrderLoading(false);
    }
  };

  if (isLoading || isOrderLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "60vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <ToastContainer />
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
        }}
      >
        <Box mb={2} width="100%">
          {customerProducts.length > 0 ? (
            customerProducts.map((produtosCliente) => (
              <ProductGroupAccordion
                key={produtosCliente.cliente.cnpjCpf}
                customerProducts={produtosCliente}
                selectedDates={selectedDates}
                clearSelectedDates={clearDates}
                onProductCheck={handleProductCheck}
                refreshCount={refreshCount}
              />
            ))
          ) : (
            <EmptyItems message="Carrinho vazio">
              <Link href="/lista/produtos" sx={{ width: "100%" }}>
                <Button
                  fullWidth
                  sx={{ mt: 2 }}
                  variant="contained"
                  color="info"
                >
                  Voltar para o inicio
                </Button>
              </Link>
            </EmptyItems>
          )}
        </Box>
        {customerProducts.length > 0 && (
          <SummaryBox
            selectedDates={selectedDates}
            customerProducts={customerProducts}
            totalAmount={totalAmount}
            orderConfirmation={() =>
              handleGerarPedido(
                selectedDates[0].clientId,
                currentUser,
                selectedDates[0].dates
              )
            }
          />
        )}
      </Box>
    </Box>
  );
};
