/**
 *
 * @param {String} document
 * @description Identifica se é CPF ou CNPJ pela quantidade de caracteres, e executa a máscara correspondente.
 * @returns {String} document com a máscara aplicada
 */

export const documentMask = (document) => {
  if (document.length < 14) {
    return document.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  return document.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    "$1.$2.$3/$4-$5"
  );
};
