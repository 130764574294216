import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader,
  IconButton,
  ListItemIcon,
  ListItem,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { useTabelasPrecoContext } from "../contexts/TabelasPreco";
import { Settings } from "@mui/icons-material";
import { useBreakpoints } from "../hooks/useBreakpoints";
import { blue, grey, orange } from "@mui/material/colors";
import { toast } from "react-toastify";
import { useSelectedProductsContext } from "../contexts/SelectedProductsContext";

export const ConfigTabela = ({ modalIsOpen, handleOpenModal }) => {
  const { tabelasPrecoRepresentante, selectedTabelaPreco, dispatch } =
    useTabelasPrecoContext();

  const { clearSelectedProducts } = useSelectedProductsContext();

  const isMobile = useBreakpoints("md");

  const handleChangeTabelaPreco = (event) => {
    dispatch({
      type: "SET_SELECTED_TABELA_PRECO",
      payload: event.target.value,
    });

    toast.success("Tabela de preço alterada com sucesso!", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

    dispatch({
      type: "SET_MODAL_IS_OPEN",
      payload: false,
    });

    clearSelectedProducts();

    handleOpenModal();
  };

  const tabelasPrecoVer = tabelasPrecoRepresentante.filter(
    (item) =>
      item.tabelapreco.includes("VER") && item.tabelapreco.startsWith("BR")
  );

  const tabelasPrecoInverno = tabelasPrecoRepresentante.filter(
    (item) =>
      item.tabelapreco.includes("INV") && item.tabelapreco.startsWith("BR")
  );

  return (
    <>
      {!isMobile && (
        <Tooltip title="Configurações de Tabela">
          <IconButton
            variant="text"
            sx={{
              color: "#FFF",
              "&:hover": {
                color: orange[300],
              },
            }}
            onClick={handleOpenModal}
          >
            <Settings />
          </IconButton>
        </Tooltip>
      )}

      {isMobile && (
        <ListItem
          onClick={() => {
            handleOpenModal();
            handleDrawerClose();
          }}
          sx={{ color: !isMobile && grey[100], my: 1 }}
        >
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText primary="Tabelas de Preço" />
        </ListItem>
      )}

      {modalIsOpen && (
        <Dialog open={modalIsOpen} onClose={handleOpenModal} fullWidth>
          <DialogTitle>Configurações - Representante</DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr",
                alignItems: "center",
                gap: 2,
                my: 2,
                width: "100%",
              }}
            >
              <FormControl sx={{ mr: !isMobile && 4 }} fullWidth>
                <InputLabel id="choose-tabela-preco-con">
                  Tabela de Preco
                </InputLabel>
                <Select
                  labelId="choose-tabela-preco-con"
                  label="Tabela de Preço"
                  value={selectedTabelaPreco || ""}
                  onChange={handleChangeTabelaPreco}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: "calc(100% - 96px)",
                      },
                    },
                  }}
                >
                  <ListSubheader
                    sx={{
                      backgroundColor: orange[400],
                      fontWeight: 600,
                      color: "#fff",
                    }}
                  >
                    Verão
                  </ListSubheader>
                  {tabelasPrecoVer.map((item) => (
                    <MenuItem value={item} key={`${item.tabelapreco}`}>
                      {item.tabelapreco}
                    </MenuItem>
                  ))}

                  {tabelasPrecoVer.length === 0 && (
                    <MenuItem>
                      Não existem tabelas de verão disponiveis
                    </MenuItem>
                  )}
                  <ListSubheader
                    sx={{
                      backgroundColor: blue[400],
                      fontWeight: 600,
                      color: "#fff",
                    }}
                  >
                    Inverno
                  </ListSubheader>
                  {tabelasPrecoInverno.map((item) => (
                    <MenuItem value={item} key={`${item.tabelapreco}`}>
                      {item.tabelapreco}
                    </MenuItem>
                  ))}

                  {tabelasPrecoInverno.length === 0 && (
                    <MenuItem>
                      Não existem tabelas de verão disponiveis
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleOpenModal}>Fechar</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
