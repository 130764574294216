import React, { useState, useEffect } from "react";
import api from "../../../../services/api";
import { Box, Button, CircularProgress } from "@mui/material";

import { ProductFilter } from "./Components/ProductFilter";
import { useBreakpoints } from "../../../../hooks/useBreakpoints";

import { useFavorites } from "../../../../hooks/useFavorites";

import ExpressDates from "./Components/ExpressDates";

import { DefaultLayout } from "../../../../layouts/DefaultLayout";
import { ExpressProductList } from "./Components/ExpressProductList";
import { ItemsCountBar } from "./Components/ItemsCountBar";

import { useSelectedProductsContext } from "../../../../contexts/SelectedProductsContext";
import { useLoggedUser } from "../../../../contexts/LoggedUserContext";

import { ProductsListModal } from "./Components/ProductsListModal";
import { useQuery } from "react-query";

export const ProdutosSemana = () => {
  const [generatePdf, setGeneratePdf] = useState(false);
  const { loggedUser } = useLoggedUser();

  const { selectedProducts, clearSelectedProducts } =
    useSelectedProductsContext();

  const storagedDates = JSON.parse(
    sessionStorage.getItem("@Express:SelectedDates")
  );

  const [selectedDates, setSelectedDates] = useState(storagedDates || []);
  const { isLoading: favoriteLoading } = useFavorites();

  /**
   * @description useStates para input de filtro.
   */
  const [filterOptions, setFilterOptions] = useState({
    inputFilter: "",
    selectFilter: null,
  });

  const isMobile = useBreakpoints("md");

  const togglePdfGenerator = () => {
    setGeneratePdf(!generatePdf);
  };

  const fetchProductsTypes = async () => {
    try {
      const response = await api.post(
        `/listaEstoqueRepImed/semana/tipoproduto?tipoUsuario=${loggedUser.tipo}`,
        {
          semanas: selectedDates,
        }
      );

      return response.data.records;
    } catch (error) {
      console.error("Error fetching product types:", error);
    }
  };

  const { data: productTypeList, isLoading: isLoadingTypeList } = useQuery(
    ["product-type-list", selectedDates],
    fetchProductsTypes,
    {
      enabled: selectedDates.length > 0,
    }
  );

  const handleDateSelectionChange = (updatedSelectedDates) => {
    setSelectedDates(updatedSelectedDates);

    sessionStorage.setItem(
      "@Express:SelectedDates",
      JSON.stringify(updatedSelectedDates)
    );
  };

  const handleOptionChange = (event) => {
    setFilterOptions({ ...filterOptions, selectFilter: event.target.value });
  };

  return (
    <DefaultLayout enableModalOnStart>
      {favoriteLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "grid",
              gridTemplateAreas: !isMobile
                ? "'express-dates product-filter product-type'"
                : `'express-dates express-dates'
                   'product-filter product-type'`,
              gridTemplateColumns: !isMobile ? "1fr 1fr 1fr" : "1fr",
              alignItems: !isMobile && "flex-start",
              gap: 1,
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "baseline",
                gridArea: "express-dates",
                width: "100%",
                justifyContent: "flex-start",
              }}
            >
              <ExpressDates
                selectedDates={selectedDates}
                handleSelectDate={handleDateSelectionChange}
                generatePdf={togglePdfGenerator}
              />

              {/* <IconButton color="primary">
                <DocumentScanner />
              </IconButton> */}
            </Box>
            <Box
              sx={{
                gridArea: "product-filter",
                display: "flex",
                width: "100%",
                justifyContent: !isMobile && "flex-end",
              }}
            >
              {
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "baseline",
                    gap: 2,
                  }}
                >
                  <ProductFilter
                    key={"product-search"}
                    width={"100%"}
                    type={"search"}
                    disableText={filterOptions.selectFilter !== null}
                    filterValue={filterOptions.inputFilter}
                    onFilterChange={(e) =>
                      setFilterOptions({
                        ...filterOptions,
                        inputFilter: e.target.value,
                      })
                    }
                  />
                </Box>
              }
            </Box>
            <Box
              sx={{
                gridArea: "product-type",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <ProductFilter
                key={"product-type"}
                filterByProductType={filterOptions.selectFilter}
                type="select"
                productTypeOptions={productTypeList}
                onFilterByProductTypeChange={handleOptionChange}
                onClearFilter={() =>
                  setFilterOptions({ ...filterOptions, selectFilter: null })
                }
              />

              {!isMobile && (
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    onClick={() => clearSelectedProducts()}
                    color="error"
                    variant="text"
                    disabled={
                      selectedProducts.size === 0 ||
                      selectedProducts.length === 0
                    }
                    sx={{
                      my: 1,
                    }}
                  >
                    Limpar {selectedProducts.size} itens selecionados
                  </Button>
                </Box>
              )}
            </Box>
          </Box>

          {isMobile && (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={() => clearSelectedProducts()}
                color="error"
                variant="text"
                disabled={selectedProducts.size === 0}
                sx={{
                  my: 1,
                }}
              >
                Limpar {selectedProducts.size} itens selecionados
              </Button>
            </Box>
          )}
          {selectedDates.length > 0 || filterOptions.inputFilter ? (
            <ExpressProductList
              expressDates={selectedDates}
              filter={filterOptions}
            />
          ) : null}

          {selectedProducts.size > 0 && <ItemsCountBar />}
        </>
      )}

      {generatePdf && (
        <ProductsListModal
          open={generatePdf}
          onClose={togglePdfGenerator}
          dates={selectedDates}
        />
      )}
    </DefaultLayout>
  );
};
