import { createContext, useContext, useState, useEffect } from "react";

const SelectedProductsContext = createContext();

export const SelectedProductsProvider = ({ children }) => {
  const [selectedProducts, setSelectedProducts] = useState(new Set());

  useEffect(() => {
    localStorage.setItem(
      "@Express:SelectedProducts",
      JSON.stringify([...selectedProducts])
    );
  }, [selectedProducts]);

  const clearSelectedProducts = () => {
    setSelectedProducts(new Set());
  };

  const toggleSelection = (selectedProduct) => {
    setSelectedProducts((prevSelectedProducts) => {
      const newSelectedProducts = new Set(prevSelectedProducts);
      if (
        [...newSelectedProducts].find(
          (product) => product.id === selectedProduct.id
        )
      ) {
        newSelectedProducts.delete(selectedProduct);
      } else {
        newSelectedProducts.add(selectedProduct);
      }
      return newSelectedProducts;
    });
  };

  const updateSelectedProductsList = (productsList) => {
    setSelectedProducts(productsList);

    console.log(productsList);
  };

  const isSelected = (productId) => {
    return [...selectedProducts].some((product) => product.id === productId);
  };

  return (
    <SelectedProductsContext.Provider
      value={{
        isSelected,
        toggleSelection,
        selectedProducts,
        clearSelectedProducts,
        updateSelectedProductsList,
      }}
    >
      {children}
    </SelectedProductsContext.Provider>
  );
};

export const useSelectedProductsContext = () => {
  return useContext(SelectedProductsContext);
};
