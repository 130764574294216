import { Text, View } from "@react-pdf/renderer";
import { styles } from "./styles";
import { currencyFormatter } from "../../../../../utils/currencyFormatter";

export const InvoiceTotal = ({ total }) => (
  <View style={{ width: "100%", flexDirection: "row" }}>
    <View style={styles.total}>
      <Text></Text>
    </View>
    <View style={styles.total}>
      <Text></Text>
    </View>
    <View style={styles.total}>
      <Text> </Text>
    </View>
    <View style={styles.tbody}>
      <Text>Total</Text>
    </View>
    <View style={styles.tbody}>
      <Text>{currencyFormatter(total)}</Text>
    </View>
  </View>
);
