import React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  IconButton,
  Box,
  Chip,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { formatarData } from "../../utils/utils";
import { grey } from "@mui/material/colors";

export const MobileApprovalCard = ({
  user,
  roles,
  handleDialogIsOpen,
  handleSelectUser,
}) => {
  const { codUsuario } = user;

  return (
    <Card
      sx={{ mb: 1 }}
      onClick={() => {
        handleSelectUser(user);
        handleDialogIsOpen();
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="subtitle1">{codUsuario?.nome}</Typography>

          <Chip
            color={user.status === 1 ? "success" : "error"}
            label={user.status === 1 ? "Ativo" : "Inativo"}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Typography textAlign={"start"} variant="subtitle2" color={grey[700]}>
            Solicitação {formatarData(user.dtSolicitacao)}
          </Typography>
          <Typography textAlign={"end"} variant="subtitle2" color={grey[700]}>
            Expiração {formatarData(user.expira)}
          </Typography>
        </Box>
      </CardContent>
      <CardActions>
        <Chip
          color={roles[codUsuario?.privilegios?.subnvlPrivilegio]?.color}
          label={
            roles[codUsuario?.privilegios?.subnvlPrivilegio]?.name ||
            "Não definido"
          }
          sx={{ ml: 1 }}
        />
        <Chip
          color={roles[codUsuario?.tipo]?.color}
          label={roles[codUsuario?.tipo]?.name || "Não definido"}
          sx={{ ml: 1 }}
        />
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          <IconButton
            sx={{ backgroundColor: "#f8f8f8" }}
            onClick={() => {
              handleDialogIsOpen();
            }}
          >
            <Edit color="warning" />
          </IconButton>
        </Box>
      </CardActions>
    </Card>
  );
};
