import {
  ExtendedCardContent,
  ExtendedCardDescription,
  ExtendedCardTitle,
  ExtendedImageContainer,
} from "./styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { ProductAvailability } from "./ProductAvailability";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Fragment, useState } from "react";
import { currencyFormatter } from "../../../../../utils/currencyFormatter";
import Image from "mui-image";

export const ProductCardFavoritos = ({ product, children, onDelete }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [openDialog, setOpenDialog] = useState(false);
  const [fullImageDialog, setFullImageDialog] = useState(false);

  const handleFullImageDialog = () => {
    setFullImageDialog(!fullImageDialog);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDelete = () => {
    onDelete();
    setOpenDialog(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        backgroundColor: "#fff",
        boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1);",
      }}
      p={2}
    >
      <Box
        sx={{
          backgroundColor: "#faf5f5",
          display: "flex",
          justifyContent: "center",
          width: isMobile ? "100%" : "20%",
          borderRadius: "4px",
          maxHeight: !isMobile && 200,
          p: 1,
        }}
        onClick={handleFullImageDialog}
      >
        <Image
          src={`https://democrata-public-files.s3.amazonaws.com/${product.referencia}.png`}
          alt={product.descricao}
          fit="contain"
          width={isMobile ? "60%" : "auto"}
        />
      </Box>

      <Box sx={{ ml: !isMobile && 2, width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-between",
            mt: isMobile && 2,
          }}
        >
          <Box>
            <Typography variant="subtitle1" fontSize={18} fontWeight={600}>
              {product.linha} {product.cor}
            </Typography>

            <ExtendedCardDescription>
              <Typography fontWeight="bold" component="span" color="#000">
                Referência:
              </Typography>{" "}
              {product.referencia}
            </ExtendedCardDescription>
          </Box>
        </Box>

        <Accordion sx={{ mt: 1.5 }} disabled={product.estoque.length === 0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {product.estoque.length > 0 ? (
              <Typography
                fontWeight="bold"
                variant="h6"
                fontSize={16}
                color={"#343a40"}
              >
                Datas disponíveis:{" "}
              </Typography>
            ) : (
              <Typography fontWeight="bold" fontSize={16} ml={1} color="#000">
                Não há disponibilidade
              </Typography>
            )}
          </AccordionSummary>

          <AccordionDetails sx={{ p: isMobile && 0, px: 1 }}>
            <ProductAvailability product={product} onDelete={onDelete} />
          </AccordionDetails>
        </Accordion>

        <Fragment>{children}</Fragment>

        <Box textAlign={"end"} mt={3}>
          <Button
            size="small"
            sx={{ minWidth: 140 }}
            color="error"
            fullWidth={isMobile}
            variant={"contained"}
            onClick={handleOpenDialog}
          >
            Remover
          </Button>
        </Box>

        <Dialog
          open={fullImageDialog}
          onClose={handleFullImageDialog}
          fullScreen={isMobile}
        >
          <DialogContent>
            <Image
              src={`https://democrata-public-files.s3.amazonaws.com/${product.referencia}.png`}
              alt={product.descricao}
              fit="contain"
              width="100%"
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleFullImageDialog}
              color="primary"
              variant={isMobile ? "contained" : "text"}
              fullWidth={isMobile}
              sx={{ my: 2, mx: 2 }}
            >
              Fechar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Deletar Favorito</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Você tem certeza que quer remover este produto?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancelar
            </Button>
            <Button
              onClick={handleConfirmDelete}
              color="error"
              variant="contained"
            >
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};
