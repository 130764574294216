import React, { createContext, useContext, useEffect, useReducer } from "react";
import api from "../services/api";

import { useLoggedUser } from "./LoggedUserContext";

export const TabelasPrecoContext = createContext();

const initialState = {
  tabelasPrecoRepresentante: [],
  tabelasPrecoConsumidorFinal: [],
  selectedTabelaPreco: null,
  selectedTabelaPrecoConsumidorFinal: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_TABELAS_PRECO_CONSUMIDOR_FINAL":
      return {
        ...state,
        tabelasPrecoConsumidorFinal: action.payload,
      };
    case "SET_TABELAS_PRECO":
      return {
        ...state,
        tabelasPrecoRepresentante: action.payload,
      };
    case "SET_SELECTED_TABELA_PRECO":
      return {
        ...state,
        selectedTabelaPreco: action.payload,
      };
    case "SET_SELECTED_TABELA_PRECO_CONSUMIDOR_FINAL":
      return {
        ...state,
        selectedTabelaPrecoConsumidorFinal: action.payload,
      };
    default:
      return state;
  }
};

export const TabelasPrecoProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { loggedUser, currentUser } = useLoggedUser();

  const fetchTabelasPrecoRepresentante = async (representante) => {
    try {
      const response = await api.get(
        `tabela-preco/representante?representante=${representante}`
      );

      if (response.status === 200) {
        dispatch({
          type: "SET_TABELAS_PRECO",
          payload: response.data.records,
        });

        dispatch({
          type: "SET_SELECTED_TABELA_PRECO",
          payload: response.data.records[0],
        });
      }
    } catch (error) {
      console.error("Error fetching tabelas de preco:", error);
    }
  };

  const fetchTabelasPrecoConsumidorfinal = async (consumidorFinal) => {
    try {
      const response = await api.get(
        `vw-tabelas-precos?consumidorFinal=${consumidorFinal}`
      );

      if (response.status === 200) {
        const allRecords = response.data.records;
        const verRecords = allRecords.filter((record) =>
          record.tabelapreco.includes("VER")
        );
        const invRecords = allRecords.filter((record) =>
          record.tabelapreco.includes("INV")
        );

        const filteredSummerRecords = verRecords.sort((a, b) => {
          if (a.tabelapreco < b.tabelapreco) return -1;
          if (a.tabelapreco > b.tabelapreco) return 1;
          return 0;
        });

        const filteredWinterRecords = invRecords.sort((a, b) => {
          if (a.tabelapreco < b.tabelapreco) return -1;
          if (a.tabelapreco > b.tabelapreco) return 1;
          return 0;
        });

        const filteredRecords = [
          ...filteredSummerRecords,
          ...filteredWinterRecords,
        ];

        dispatch({
          type: "SET_TABELAS_PRECO_CONSUMIDOR_FINAL",
          payload: [...filteredSummerRecords, ...filteredWinterRecords],
        });

        dispatch({
          type: "SET_SELECTED_TABELA_PRECO_CONSUMIDOR_FINAL",
          payload: "TABELA_CONS_2_INV24",
        });
      }
    } catch (error) {
      console.error("Error fetching tabelas de preco:", error);
    }
  };

  const fetchPriceForSeletedProducts = async (products) => {};

  useEffect(() => {
    Promise.all([
      fetchTabelasPrecoRepresentante(currentUser),
      fetchTabelasPrecoConsumidorfinal("S"),
    ]);
  }, []);

  return (
    <TabelasPrecoContext.Provider
      value={{
        tabelasPrecoRepresentante: state.tabelasPrecoRepresentante,
        tabelasPrecoConsumidorFinal: state.tabelasPrecoConsumidorFinal,
        selectedTabelaPrecoConsumidorFinal:
          state.selectedTabelaPrecoConsumidorFinal,
        selectedTabelaPreco: state.selectedTabelaPreco,
        fetchPriceForSeletedProducts,
        dispatch,
      }}
    >
      {children}
    </TabelasPrecoContext.Provider>
  );
};

export const useTabelasPrecoContext = () => {
  return useContext(TabelasPrecoContext);
};
