import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ExtendedCardGrid, ExtendedCardGridItem } from "./styles";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { formatarData } from "../../../../../utils/utils";
import { useProductContext } from "../../../../../contexts/ProductContext";
import { Grade } from "../../../../../components/GradeExpress/Grade";

export const ProductAvailability = ({ product }) => {
  const { selecionarProduto } = useProductContext();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {product.estoque.length > 0 && (
        <Box
          sx={{
            display: "grid",
            gap: "16px",
            mb: isMobile && 4,
          }}
        >
          {product.estoque.map((productGrade, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                px: 2,
                flexDirection: "column",
                border: "1px solid #e8e8e8",
                borderRadius: "5px",
              }}
            >
              <Typography
                my={1}
                variant="h6"
                fontSize={16}
                sx={{ color: "#ed6c02" }}
              >
                {formatarData(productGrade.semana)}
              </Typography>
              <Box
                mb={1}
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ExtendedCardGrid>
                  {productGrade.gradeProduto.map((item) => (
                    <Grade nro={item.nro} estoque={item.estoque} />
                  ))}
                </ExtendedCardGrid>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "end",
                    flexDirection: "column",
                  }}
                >
                  <Box mt={2} width="100%" textAlign={"end"}>
                    <Link to={`clientes/${productGrade.referencia}`}>
                      <Button
                        size="small"
                        sx={{
                          minWidth: 140,
                          "&:hover": {
                            bgcolor: "primary.warning",
                          },
                        }}
                        variant="outlined"
                        fullWidth={isMobile}
                        color="warning"
                        onClick={() =>
                          selecionarProduto({
                            ...productGrade,
                            data: formatarData(productGrade.semana),
                            favorito: true,
                          })
                        }
                      >
                        Pedir
                      </Button>
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};
