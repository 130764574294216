import { ProdutosSelecionadosGrid } from "./components/ProdutosSelecionadosGrid";
import { DefaultLayout } from "../../../layouts/DefaultLayout";

const ProdutosGrade = () => {
  const breadcrumbs = [
    { label: "Produtos", url: "/lista/produtos" },
    { label: "Clientes", url: "/clientes" },
    { label: "Grade Padrão", url: "#" },
  ];

  return (
    <DefaultLayout breadcrumbs={breadcrumbs}>
      <ProdutosSelecionadosGrid />
    </DefaultLayout>
  );
};

export default ProdutosGrade;
