import { DefaultLayout } from "../../../layouts/DefaultLayout";
import { FavoritosList } from "./Components/FavoritosList";

const Favoritos = () => {
  return (
    <DefaultLayout
      breadcrumbs={[
        {
          label: "Favoritos",
          url: "/favoritos",
        },
      ]}
    >
      <FavoritosList />
    </DefaultLayout>
  );
};

export default Favoritos;
