import api from "../../../../services/api";
import {
  productWithGrade,
  productWithGradeCheckout,
} from "../../../../utils/productWithGrade";

export const fetchProdutosClientes = async (representante) => {
  try {
    const response = await api.get(
      `/i-checkout-tmp/lista?reprVendedor=${representante}`
    );

    const processedItemsArray = response.data.records.map((record) => {
      return {
        ...record,
        itens: productWithGradeCheckout(record.itens, new Date()),
      };
    });

    return processedItemsArray;
  } catch (error) {
    console.error("Error fetching produtosClientes:", error);
    return [];
  }
};
