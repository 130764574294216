import api from "../../../../services/api";
import { productWithReservado } from "../../../../utils/productWithGrade";

export const fetchProductTypes = async () => {
  try {
    const response = await api.get("/grade-padrao/ativos");
    return response.data.records;
  } catch (error) {
    console.error("Error fetching product types:", error);
  }
};

export const fetchStandardGrade = async (selectedType, selectedGrade) => {
  if (selectedType && selectedGrade) {
    try {
      const response = await api.get(
        `/grade-padrao?tipo=${selectedType}&qtde=${selectedGrade}`
      );

      return response.data.records;
    } catch (error) {
      console.error("Error fetching grade:", error);
    }
  }
};

export const loadProductsFromSession = (sessionProducts) => {
  return sessionProducts ? JSON.parse(sessionProducts) : [];
};

const addReservedObjectToProduct = async (product) => {
  try {
    const productFormattedDate = new Date(product.semana).toLocaleDateString(
      "pt-BR",
      { timeZone: "UTC" }
    );

    const response = await api.post(`/i-checkout-tmp/itens-temporaria`, {
      codigoReferencia: product.referencia,
      dataExpress: productFormattedDate,
    });

    return productWithReservado(product, response.data);
  } catch (err) {
    console.log("Error fetching reserved amount:", err);
  }
};

export const loadedTransformedProducts = async () => {
  const sessionProductsData = sessionStorage.getItem(
    "@Express:SessionProducts"
  );

  if (!sessionProductsData) {
    return [];
  }

  const sessionProducts = JSON.parse(sessionProductsData);

  const validProducts = sessionProducts.filter(
    (product) => product.semana !== undefined
  );

  sessionStorage.setItem(
    "@Express:SessionProducts",
    JSON.stringify(validProducts)
  );

  const productsWithReserved = await Promise.all(
    validProducts.map(async (product) => ({
      ...product,
      gradePadrao: product.gradePadrao || [],
      reservado: await addReservedObjectToProduct(product),
    }))
  );

  return productsWithReserved;
};
