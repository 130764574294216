import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Header from "../../../components/Header";
import Image from "mui-image";
import Box from "@mui/system/Box";
import { FavoriteBorderOutlined, FavoriteSharp } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { grey } from "@mui/material/colors";
import api from "../../../services/api";
import { UserIdent } from "../../../components/controles/UserIdent";

import { ProductTable } from "./ProductTable/ProductTable";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useProductContext } from "../../../contexts/ProductContext";

import transformProductJsonToCheckout from "../../../utils/transformProductJsonToCheckout";
import { DefaultLayout } from "../../../layouts/DefaultLayout";
import { currencyFormatter } from "../../../utils/currencyFormatter";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLoggedUser } from "../../../contexts/LoggedUserContext";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useTabelasPrecoContext } from "../../../contexts/TabelasPreco";
import { useReservedAmount } from "../../../hooks/useReservedAmount";
import { formatarData } from "../../../utils/utils";
import { productWithReservado } from "../../../utils/productWithGrade";

export const ProdutoDetalhe = () => {
  const queryClient = useQueryClient();

  const { selectedTabelaPreco } = useTabelasPrecoContext();

  const [isFavorite, setIsFavorite] = useState(false);

  const { referencia } = useParams();

  const history = useHistory();

  const { loggedUser, currentUser } = useLoggedUser();

  const isMobileScreen = useMediaQuery("(min-width:830px)");

  const produtoSelecionado = JSON.parse(
    localStorage.getItem("@Express:ProdutoSelecionado")
  );

  const { gradeSelecionada } = useProductContext();

  const { reservedAmount } = useReservedAmount(
    produtoSelecionado.referencia,
    formatarData(produtoSelecionado.semana)
  );

  const clienteInfo = JSON.parse(localStorage.getItem("@CLIENTE"));

  const { data: favoritePrice, isLoading } = useQuery(
    ["favoritePrice", referencia, selectedTabelaPreco],
    async () => {
      const [responseRepresentantPrice, responseConsumerPrice] =
        await Promise.all([
          api.get(
            `/vw-tabelas-precos/item?referencia=${referencia}&tabelaPreco=${selectedTabelaPreco.tabelapreco}`
          ),
          api.get(
            `/vw-tabelas-precos/item?referencia=${referencia}&tabelaPreco=TABELA_CONS_1_INV24`
          ),
        ]);

      return {
        precoVenda: responseRepresentantPrice.data.records[0].preco,
        precoSugerido: responseConsumerPrice.data.records[0].preco,
      };
    }
  );

  const handleFavoriteItem = async () => {
    setIsFavorite(true);

    const { referencia } = produtoSelecionado;
    const { vendedor } = clienteInfo;

    const apiJSON = {
      referencia: referencia,
      usuario: vendedor.cnpjCpf,
    };

    await api.post("/favoritos", apiJSON);
  };

  const mutation = useMutation(
    (dadosJson) => api.post("/i-checkout-tmp", dadosJson),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("checkout");
        history.push("/checkout");
      },
    }
  );

  const handleAddProduct = async (product, grade) => {
    const updatedProduct = {
      ...product,
      pedido: grade,
    };

    const quants = {};

    updatedProduct.pedido.forEach((gradeItem) => {
      const gradeKey = Object.keys(product.grade).find(
        (key) => product.grade[key] === gradeItem.nro.toString()
      );
      if (gradeKey) {
        const quantNumber = gradeKey.replace("nro", "");
        quants[`quant${quantNumber}`] = gradeItem.estoque;
      }
    });

    const totalQuant = Object.values(quants).reduce(
      (acc, curr) => acc + curr,
      0
    );

    const dadosJson = {
      reprVendedor: currentUser,
      cliente: clienteInfo.cliente.cnpjCpf,
      preco: favoritePrice.precoVenda,
      referencia: updatedProduct.referencia,
      conceito: updatedProduct.conceito,
      linha: updatedProduct.linha,
      cor: updatedProduct.cor,
      entrega: updatedProduct.data,
      grade: { grade: updatedProduct.tipoProduto === "CINTO" ? 3 : 1 },
      ...quants,
      quantidade: totalQuant,
      unitario: favoritePrice.precoVenda,
      tabelaPreco: selectedTabelaPreco.tabelapreco,
    };

    mutation.mutate(dadosJson);
  };

  const favoriteBreadcrumbs = [
    { label: "Favoritos", url: "/favoritos" },
    { label: "Clientes", url: "/clientes" },
    { label: "Detalhes Produto", url: `/produto/${referencia}` },
  ];

  if (isLoading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );

  return (
    <>
      <DefaultLayout breadcrumbs={favoriteBreadcrumbs}>
        <div className="container">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
            mb={1}
          >
            <Typography variant="h6" color={grey[700]}>
              Referência: {referencia}
            </Typography>
            {!isMobileScreen ? (
              <IconButton onClick={handleFavoriteItem} color="error">
                {isFavorite ? <FavoriteSharp /> : <FavoriteBorderOutlined />}
              </IconButton>
            ) : (
              <Button
                onClick={handleFavoriteItem}
                disabled={produtoSelecionado.favorito}
                startIcon={
                  isFavorite ? <FavoriteSharp /> : <FavoriteBorderOutlined />
                }
                color="error"
              >
                {produtoSelecionado.favorito
                  ? "Item adicionado a lista de favoritos"
                  : "Adicionar a Lista de Favoritos"}
              </Button>
            )}
          </Box>
          <div className="row d-flex justify-content-between">
            {!isMobileScreen ? (
              <>
                <Box mb={2} width="100%" height="300px">
                  <Image
                    fit="contain"
                    bgColor="#faf5f5"
                    wrapperStyle={{
                      padding: "1rem",
                      borderRadius: "0.5rem",
                    }}
                    src={`https://democrata-public-files.s3.amazonaws.com/${referencia}.png`}
                    width={"100%"}
                    height={"100%"}
                    showLoading={
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    }
                  />
                </Box>
                <div className="col-lg-6 mr-4">
                  <Box>
                    <Typography variant="h4" fontWeight={500}>
                      {produtoSelecionado.descricaoRegra}
                    </Typography>
                    <Box mt={1}>
                      <Typography variant="subtitle1">
                        Preço recomendado:{" "}
                        {currencyFormatter(favoritePrice.precoVenda || 0)}
                      </Typography>
                      <Typography variant="subtitle1">
                        Preço venda:{" "}
                        {currencyFormatter(favoritePrice.precoSugerido || 0)}
                      </Typography>
                    </Box>
                  </Box>

                  <Box mt={2}>
                    <ProductTable
                      initialProductData={{
                        ...produtoSelecionado,
                        reservado:
                          productWithReservado(
                            produtoSelecionado,
                            reservedAmount
                          ) || [],
                      }}
                    />
                  </Box>

                  <Button
                    variant="contained"
                    color="error"
                    size="large"
                    sx={{ marginTop: 2 }}
                    onClick={() =>
                      handleAddProduct(produtoSelecionado, gradeSelecionada)
                    }
                  >
                    Adicionar
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className="col-lg-6 mr-4">
                  <Box>
                    <Typography variant="h4" fontWeight={500}>
                      {produtoSelecionado.descricaoRegra}
                    </Typography>
                    <Box mt={1}>
                      <Typography variant="subtitle1">
                        Preço venda:{" "}
                        {currencyFormatter(favoritePrice.precoVenda || 0)}
                      </Typography>
                      <Typography variant="subtitle1">
                        Preço recomendado:{" "}
                        {currencyFormatter(favoritePrice.precoSugerido || 0)}
                      </Typography>
                    </Box>
                  </Box>
                  <Box mt={2}>
                    <ProductTable
                      initialProductData={{
                        ...produtoSelecionado,
                        reservado:
                          productWithReservado(
                            produtoSelecionado,
                            reservedAmount
                          ) || [],
                      }}
                    />
                  </Box>
                  <Box
                    mt={1}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {/* <Typography variant="subtitle1" sx={{ fontSize: 16 }}>
                      Total
                    </Typography> */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end",
                      }}
                    >
                      {/* <Typography variant="subtitle1">R$600,00</Typography> */}

                      <Button
                        variant="contained"
                        color="error"
                        size="large"
                        sx={{ marginTop: 2 }}
                        onClick={() =>
                          handleAddProduct(produtoSelecionado, gradeSelecionada)
                        }
                      >
                        Adicionar
                      </Button>
                    </Box>
                  </Box>
                </div>
                <Box>
                  <Image
                    fit="contain"
                    bgColor="#faf5f5"
                    wrapperStyle={{
                      padding: "1rem",
                      borderRadius: "0.5rem",
                    }}
                    src={`https://democrata-public-files.s3.amazonaws.com/${referencia}.png`}
                    width={400}
                    height={400}
                    showLoading={
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    }
                  />
                </Box>
              </>
            )}
          </div>
        </div>
      </DefaultLayout>
    </>
  );
};
