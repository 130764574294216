export const formatarData = (stringData, DataHora) => {
  let dataInput = stringData;
  let data = new Date(dataInput);
  let dataFormatada = data.toLocaleDateString("pt-BR", { timeZone: "UTC" });
  let horaFormatada = data.toLocaleTimeString("pt-BR", { timeZone: "UTC" });
  if (DataHora == "DataHora") {
    return dataFormatada + " " + horaFormatada;
  } else {
    return dataFormatada;
  }
};

export const formatarDataBr = (stringData) => {
  let dataInput = stringData;
  let data = new Date(dataInput);
  let dataFormatada = data.toLocaleDateString("pt-BR", { timeZone: "UTC" });
  let horaFormatada = data.toLocaleTimeString("pt-BR", { timeZone: "UTC" });

  return dataFormatada + " " + horaFormatada;
};

export default { formatarDataBr, formatarData };
