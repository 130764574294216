import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useTabelasPrecoContext } from "../../../../contexts/TabelasPreco";

export const DiscountFields = ({
  fields,
  discounts,
  handleChangeDiscounts,
}) => {
  const { selectedTabelaPreco } = useTabelasPrecoContext();

  const activeSecondDiscount = fields[0].tabelaPreco?.startsWith("BRC");

  return (
    <Grid container spacing={2}>
      <Grid item xs={activeSecondDiscount ? 6 : 12}>
        <FormControl fullWidth>
          <TextField
            type="text"
            name="firstDiscount"
            label="Desconto"
            value={discounts.firstDiscount}
            onChange={handleChangeDiscounts}
            defaultValue={0.0}
            min="0.0"
            step="0.01"
            InputProps={{
              endAdornment: "%",
              inputProps: {
                maxLength: 4,
              },
              inputMode: "numeric",
              pattern: "[0-5]*",
            }}
          />
        </FormControl>
      </Grid>
      {activeSecondDiscount && (
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              type="text"
              name="secondDiscount"
              label="Desconto"
              value={discounts.secondDiscount}
              onChange={handleChangeDiscounts}
              defaultValue={0.0}
              min="0.0"
              step="0.01"
              InputProps={{
                endAdornment: "%",
                inputProps: {
                  maxLength: 4,
                },
                inputMode: "numeric",
                pattern: "[0-5]*",
              }}
            />
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};
