import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";

import { useQuery, useQueryClient } from "react-query";
import api from "../../../../../services/api";

import { Button, Checkbox, Divider, Skeleton } from "@mui/material";

import { useBreakpoints } from "../../../../../hooks/useBreakpoints";
import { useTabelasPrecoContext } from "../../../../../contexts/TabelasPreco";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "fit-content",
      width: 250,
    },
  },
};

const ExpressDates = ({ selectedDates, handleSelectDate, generatePdf }) => {
  const queryClient = useQueryClient();

  const [checkedDates, setCheckedDates] = useState(selectedDates);
  const [pendingDates, setPendingDates] = useState(selectedDates);

  const { selectedTabelaPreco } = useTabelasPrecoContext();
  const isMobile = useBreakpoints("sm");

  const { data: dates, isLoading } = useQuery(
    ["datasExpress", selectedTabelaPreco],
    async () => {
      const response = await api.get("/listaDatasLiberadasExpress", {
        params: {
          tabelaPreco: selectedTabelaPreco.tabelapreco,
        },
      });

      return response.data.records;
    },
    {
      enabled: !!selectedTabelaPreco,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    setCheckedDates([]);
    setPendingDates([]);
    sessionStorage.removeItem("@Express:SelectedDates");
    handleSelectDate([]);
  }, [selectedTabelaPreco]);

  const handleChange = (event) => {
    const { value } = event.target;
    setPendingDates(value);
  };

  const handleConfirmChange = () => {
    handleSelectDate(pendingDates);
  };

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    if (checkedDates.includes(value)) {
      setCheckedDates(checkedDates.filter((date) => date !== value));
    } else {
      setCheckedDates([...checkedDates, value]);
    }
  };

  const handleMenuItemClick = (event, value) => {
    const checkbox = event.target
      .closest("li")
      .querySelector('input[type="checkbox"]');
    checkbox.checked = !checkbox.checked;
    handleCheckboxChange({ target: checkbox });
  };

  const handleCheckAll = () => {
    const allDates = dates.map((date) =>
      new Date(date.dataExpress).toLocaleDateString("pt-BR", {
        timeZone: "UTC",
      })
    );
    if (checkedDates.length === allDates.length) {
      setCheckedDates([]);
      setPendingDates([]);
    } else {
      setCheckedDates(allDates);
      setPendingDates(allDates);
    }
  };

  if (isLoading && !dates) {
    return (
      <Skeleton
        variant="rectangular"
        animation="wave"
        width={isMobile ? 150 : 300}
        height={40}
      />
    );
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
      <FormControl
        sx={{
          minWidth: isMobile ? 150 : 338,
          width: "100%",
          cursor: "pointer",
        }}
        size="small"
      >
        <InputLabel id="express-dates-label">Datas Express</InputLabel>
        <Select
          onClose={handleConfirmChange}
          labelId="express-dates-label"
          id="express-dates"
          multiple
          value={pendingDates}
          size="small"
          sx={{ minHeight: 56 }}
          onChange={handleChange}
          input={
            <OutlinedInput id="select-express-dates" label="Datas Express" />
          }
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((date) => (
                <Chip key={date} label={date} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          <MenuItem onClick={handleCheckAll}>
            <Checkbox
              checked={checkedDates.length === dates?.length}
              indeterminate={
                checkedDates.length > 0 && checkedDates.length < dates?.length
              }
            />
            {checkedDates.length === dates?.length
              ? "Desmarcar todas"
              : "Marcar todas"}
          </MenuItem>

          <Divider sx={{ my: 0.5 }} />

          {dates &&
            dates.map((date) => {
              const formattedDate = new Date(
                date.dataExpress
              ).toLocaleDateString("pt-BR", {
                timeZone: "UTC",
              });
              return (
                <MenuItem
                  key={date.dataExpress}
                  onClick={(event) => handleMenuItemClick(event, formattedDate)}
                  value={formattedDate}
                >
                  <Checkbox
                    checked={checkedDates.includes(formattedDate)}
                    onChange={handleCheckboxChange}
                    value={formattedDate}
                  />
                  {formattedDate}
                </MenuItem>
              );
            })}

          <Divider sx={{ my: 0.5 }} />

          <MenuItem
            disabled={checkedDates.length === 0}
            onClick={() => {
              handleConfirmChange();
              generatePdf();
            }}
          >
            <Button fullWidth>Gerar PDF</Button>
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default ExpressDates;
