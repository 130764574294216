import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
  Paper,
  Button,
} from "@mui/material";
import { PedidosItens } from "./PedidosItens";
import { documentMask } from "../../../../utils/documentMask";
import { grey } from "@mui/material/colors";
import { currencyFormatter } from "../../../../utils/currencyFormatter";
import { postalCodeFormatter } from "../../../../utils/postalCodeFormatter";

import { usePDF } from "react-to-pdf";
import { formatarData } from "../../../../utils/utils";

export const OrderDetails = ({ orderDetails }) => {
  // const { toPDF, targetRef } = usePDF({
  //   filename: `${orderDetails.id}-${orderDetails.clientes.razao}-${formatarData(
  //     orderDetails.datCad
  //   )}`,
  // });

  return (
    <Box p={4}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Pedido #{orderDetails.id} -{" "}
            {orderDetails.clientes.fantasia || orderDetails.clientes.razao}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle2" color={grey[700]}>
              {orderDetails.clientes.razao}
            </Typography>{" "}
            &nbsp; - &nbsp;
            <Typography variant="subtitle2" color={grey[700]}>
              CNPJ: {documentMask(orderDetails.clientes.cnpjCpf)}
            </Typography>
          </Box>
        </Box>
        {/* <Button
          color="error"
          variant="contained"
          size="small"
          onClick={() => toPDF()}
        >
          Exportar PDF
        </Button> */}
      </Box>

      <Grid container spacing={3} mt={1}>
        {/* Endereço */}
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Paper
            sx={{ p: 2, mb: 3, border: "1px solid #ccc", minHeight: "100%" }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Endereço
            </Typography>

            <List>
              <ListItem>
                <ListItemText
                  primary="Rua"
                  secondary={`${orderDetails.clientes.endereco}, Nº ${orderDetails.clientes.numero} - ${orderDetails.clientes.bairro}`}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Cidade"
                  secondary={`${orderDetails.clientes.cidade} - ${
                    orderDetails.clientes.uf
                  }, ${postalCodeFormatter(String(orderDetails.clientes.cep))}`}
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>

        {/* Pagamento */}
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Paper
            sx={{
              p: 2,
              mb: 3,
              border: "1px solid #ccc",
              minHeight: "100%",
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Pagamento
            </Typography>

            <List>
              <ListItem>
                <ListItemText
                  primary="Condição de Pagamento"
                  secondary={orderDetails.condPagamento.descricao}
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>

        {/* Resumo */}
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Paper
            sx={{ p: 2, mb: 3, border: "1px solid #ccc", minHeight: "100%" }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Resumo
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary="Subtotal"
                  secondary={currencyFormatter(orderDetails.subtotal || 0)}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Desconto"
                  secondary={currencyFormatter(orderDetails.vlrDesconto || 0)}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Total"
                  secondary={currencyFormatter(orderDetails.total || 0)}
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>

        {/* Items */}
        <Grid item xs={12}>
          <Paper sx={{ mb: 3, p: 2, backgroundColor: "#f8f8f8" }}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "bold",
                padding: "1rem",
                marginBottom: "16px",
              }}
            >
              Produtos
            </Typography>

            {orderDetails && <PedidosItens order={orderDetails} />}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
