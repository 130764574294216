import api from "../../../../services/api";

export const fetchClientesByCidade = async (
  cnpjCpf,
  selectedEstado,
  selectedCidade
) => {
  const response = await api.get(
    `clientes/consulta/${cnpjCpf}?uf=${selectedEstado}&cidade=${selectedCidade}`
  );
  return {
    customers: response.data.records,
    header: response.data.meta,
  };
};

export const fetchClientesByCnpjRazao = async (cnpjCpf, filterValue) => {
  const response = await api.get(
    `clientes/consulta/${cnpjCpf}?cnpjRazao=${filterValue}`
  );
  return {
    customers: response.data.records,
    header: response.data.meta,
  };
};

export const fetchClientesByEstado = async (cnpjCpf, selectedEstado) => {
  const response = await api.get(
    `clientes/consulta/${cnpjCpf}?uf=${selectedEstado}`
  );
  return {
    customers: response.data.records,
    header: response.data.meta,
  };
};

export const fetchClientesDefault = async (cnpjCpf) => {
  const response = await api.get(`clientes/consulta/${cnpjCpf}`);
  return {
    customers: response.data.records,
    header: response.data.meta,
  };
};

export const getClientes = async (
  userType,
  cnpjCpf,
  selectedEstado,
  selectedCidade,
  filterValue
) => {
  if (selectedCidade) {
    return fetchClientesByCidade(cnpjCpf, selectedEstado, selectedCidade);
  }

  if (!selectedEstado && !selectedCidade && filterValue.length > 0) {
    return fetchClientesByCnpjRazao(cnpjCpf, filterValue);
  }

  if (selectedEstado) {
    return fetchClientesByEstado(cnpjCpf, selectedEstado);
  }

  return fetchClientesDefault(cnpjCpf);
};
