import React, { useEffect, useState } from "react";
import { Button, Box, Card, Typography, Grid, Paper } from "@mui/material";
import { DialogConfirmation } from "../../../../components/DialogConfirmation";

import { extractGradePadraoWithoutMap } from "../../../../utils/productWithGrade";
import { GradeItemComponent } from "../../../../components/GradeExpress/Grade";
import { grey } from "@mui/material/colors";
import { isMultipleOfBaseMultiples } from "../../../../utils/isMultipleOfBaseMultiples";
import { toast } from "react-toastify";

function generateNumerations(type) {
  if (type === "SAPATO") {
    return Array.from({ length: 9 }, (_, index) => ({
      nro: (37 + index).toString(),
      estoque: null,
    }));
  }

  return Array.from({ length: 9 }, (_, index) => ({
    nro: (80 + 5 * index).toString(),
    estoque: 0,
  }));
}

export const GradeCustomizada = ({ products, refreshStorage, typeOptions }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedProductType, setSelectedProductType] = useState(null);
  const [numerations, setNumerations] = useState([]);

  useEffect(() => {
    setSelectedProductType(null);
  }, [modalIsOpen]);

  const handleSelectOptionType = (type) => {
    setSelectedProductType(type);
    setNumerations(generateNumerations(type));
  };

  const updatePedido = (index, operation) => {
    setNumerations((prevNumerations) => {
      const updatedNumerations = [...prevNumerations];
      const currentStock = updatedNumerations[index].estoque;
      if (operation === "increment") {
        updatedNumerations[index] = {
          ...updatedNumerations[index],
          estoque: currentStock + 1,
        };
      } else if (operation === "decrement" && currentStock > 0) {
        updatedNumerations[index] = {
          ...updatedNumerations[index],
          estoque: currentStock - 1,
        };
      }
      return updatedNumerations;
    });
  };

  const applyCustomGrade = () => {
    const acceptableTypes = ["SANDALIA", "SAPATO", "CALÇADO"];

    const totalQuantityInGrade = numerations.reduce(
      (acc, grade) => acc + grade.estoque,
      null
    );

    const isNumberMultiple = isMultipleOfBaseMultiples(totalQuantityInGrade);

    if (!isNumberMultiple) {
      return toast.error(
        "A quantidade total da grade deve ser múltipla de 8, 10, 12, 15 ou 18."
      );
    }

    const updatedProducts = products.map((product) => {
      if (
        product.gradePadrao.length === 0 &&
        ((selectedProductType === "CINTO" && product.tipoProduto === "CINTO") ||
          (selectedProductType === "SAPATO" &&
            acceptableTypes.includes(product.tipoProduto)))
      ) {
        return {
          ...product,
          gradePadrao: extractGradePadraoWithoutMap(
            numerations,
            product.gradeProduto,
            product.reservado
          ),
        };
      }
      return product;
    });

    sessionStorage.setItem(
      "@Express:SessionProducts",
      JSON.stringify(updatedProducts)
    );

    refreshStorage();

    setModalIsOpen(!modalIsOpen);
  };

  const handleOpenModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  return (
    <>
      <Button onClick={handleOpenModal}>Criar grade customizada</Button>

      {modalIsOpen && (
        <DialogConfirmation
          key={`custom-grade-modal`}
          open={modalIsOpen}
          onClose={handleOpenModal}
          fullWidth={false}
          title={"Grade Customizada"}
          onConfirm={applyCustomGrade}
          disableActionsButtons={!selectedProductType}
        >
          {!selectedProductType && (
            <>
              {typeOptions.map((type) => (
                <Button
                  key={type}
                  variant="contained"
                  onClick={() => handleSelectOptionType(type.tipo)}
                  sx={{ margin: 1 }}
                >
                  {type.tipo}
                </Button>
              ))}
            </>
          )}
          {selectedProductType && (
            <Grid
              container
              spacing={2}
              direction="column"
              alignItems="center"
              justifyContent="space-evenly"
            >
              {numerations.map((numeration, index) => (
                <Grid item xs={12} sm={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: 280,
                    }}
                  >
                    <Box minWidth={50}>
                      <GradeItemComponent nro={numeration.nro} />
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: "#f8f8f8",
                        mt: 0.5,
                        width: 190,
                      }}
                    >
                      <Button
                        size="small"
                        sx={{
                          backgroundColor: "#DFDFDF",
                          color: grey[900],
                          ":hover": {
                            backgroundColor: grey[400],
                          },
                        }}
                        onClick={() => updatePedido(index, "decrement")}
                      >
                        -
                      </Button>

                      <Typography color="primary">
                        {numeration.estoque || 0}
                      </Typography>

                      <Button
                        size="small"
                        sx={{
                          backgroundColor: "#DFDFDF",
                          color: grey[900],
                          ":hover": {
                            backgroundColor: grey[400],
                          },
                        }}
                        onClick={() => updatePedido(index, "increment")}
                      >
                        +
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </DialogConfirmation>
      )}
    </>
  );
};
