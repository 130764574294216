import {
  Box,
  Button,
  Card,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import { useState } from "react";
import { DialogConfirmation } from "../../../../components/DialogConfirmation";

import { useLoggedUser } from "../../../../contexts/LoggedUserContext";
import { useMutation, useQueryClient } from "react-query";

export const OrderSummary = ({
  order,
  customer,
  expressDate,
  clearSelectedDates,
}) => {
  const { loggedUser, currentUser } = useLoggedUser();
  const queryClient = useQueryClient();

  const [removeOrderDialogIsOpen, setRemoveOrderDialogIsOpen] = useState(false);
  const [isRemovingOrder, setIsRemovingOrder] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const removeCustomerOrderByDate = useMutation(
    async () => {
      setIsRemovingOrder(true);
      const response = await api.delete(
        `/i-checkout-tmp/reprvendedor-cliente-datas?reprVendedor=${currentUser}&cliente=${customer.cnpjCpf}&data=${expressDate}`
      );

      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("checkout");
        toast.success("Pedido excluído com sucesso!");
        clearSelectedDates();

        setRemoveOrderDialogIsOpen(false);
      },
      onError: (error) => {
        toast.error("Erro ao excluir pedido!");
        console.error(error);
      },
    }
  );

  const handleRemoveOrder = () => {
    setRemoveOrderDialogIsOpen(!removeOrderDialogIsOpen);
  };

  return (
    <>
      <Card
        sx={{
          p: 2,
          backgroundColor: "#fafafa",
          borderRadius: "10px",
          boxShadow: "0 4px 6px rgba(0,0,0,0.05)",
        }}
      >
        <Typography variant="h6" fontSize={16} mb={2} fontWeight="bold">
          Resumo do Pedido
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            justifyContent: "space-between",
            textAlign: "center",
            gap: "15px",
          }}
        >
          {[
            { label: "Pares", value: order.quantity },
            { label: "Itens", value: order.volume },
          ].map((item, index) => (
            <Card
              key={index}
              sx={{
                backgroundColor: "#e9e9e9",
                p: 1.5,
                minWidth: 110,
                borderRadius: "5px",
              }}
            >
              <Typography variant="subtitle2">{item.label}</Typography>
              <Typography variant="subtitle1" fontWeight="bold">
                {item.value}
              </Typography>
            </Card>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            mt: 2,
            width: "100%",
          }}
        >
          <Button
            variant="text"
            size="small"
            color="error"
            onClick={handleRemoveOrder}
          >
            Excluir pedido
          </Button>
        </Box>
      </Card>

      {removeOrderDialogIsOpen && (
        <DialogConfirmation
          open={removeOrderDialogIsOpen}
          onClose={handleRemoveOrder}
          onConfirm={() => removeCustomerOrderByDate.mutate()}
          title={"Excluir pedido"}
          isRunningRequest={isRemovingOrder}
          closeButton
        >
          <Typography variant="subtitle1" fontWeight={"bold"}>
            Tem certeza que deseja excluir este pedido?
          </Typography>
        </DialogConfirmation>
      )}
    </>
  );
};
