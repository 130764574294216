import { Document, Image, Page, StyleSheet, View } from "@react-pdf/renderer";
import { InvoiceTitle } from "./InvoiceTitle";
import { InvoiceAddress } from "./InvoiceAddress";
import { InvoiceTableHead } from "./InvoiceTableHead";
import { InvoiceTableBody } from "./InvoiceTableBody";
import { InvoiceTotal } from "./InvoiceTotal";

export const InvoicePDF = ({ order, products }) => {
  const styles = StyleSheet.create({
    page: {
      padding: 20,
    },
  });

  return (
    <>
      {products && (
        <Document>
          <Page size="A4" style={styles.page} orientation="landscape">
            <InvoiceTitle />
            <InvoiceAddress order={order} />
            <InvoiceTableHead productSizes={products} />
            <InvoiceTableBody products={products} />
            <InvoiceTotal total={order.total} />
          </Page>
        </Document>
      )}
    </>
  );
};
