import { Button } from "@mui/material";
import { ShoppingCart } from "@mui/icons-material";
import api from "../../../../services/api";
import { useBreakpoints } from "../../../../hooks/useBreakpoints";
import transformProductJsonToCheckout from "../../../../utils/transformProductJsonToCheckout";

import { useTabelasPrecoContext } from "../../../../contexts/TabelasPreco";
import { validateProductsMinQuantity } from "../../Checkout/validators/productsMinQuantity";
import { toast } from "react-toastify";
import { useLoggedUser } from "../../../../contexts/LoggedUserContext";
import { useMutation, useQueryClient } from "react-query";
import { useSelectedProductsContext } from "../../../../contexts/SelectedProductsContext";
import { useState } from "react";

export const SendToCheckout = ({
  label,
  icon,
  products,
  clearStorage,
  onProductsSent,
  ...props
}) => {
  const queryClient = useQueryClient();

  const isMobile = useBreakpoints("md");
  const clienteInfo = JSON.parse(localStorage.getItem("@CLIENTE"));

  const { selectedTabelaPreco } = useTabelasPrecoContext();
  const { clearSelectedProducts } = useSelectedProductsContext();
  const { loggedUser, currentUser } = useLoggedUser();

  const [isSendingToCart, setIsSendingToCart] = useState(false);

  function getTotalAmount(products) {
    return products.reduce((total, product) => {
      return (
        total +
        product.gradePadrao.reduce((subTotal, grade) => {
          return subTotal + grade.estoque;
        }, 0)
      );
    }, 0);
  }

  const createPreOrder = async ({
    products,
    clienteInfo,
    loggedUser,
    selectedTabelaPreco,
  }) => {
    setIsSendingToCart(true);

    const productsArray = Array.isArray(products)
      ? products
      : Array.from(products);

    const totalAmount = getTotalAmount(productsArray);

    const updatedProductsArray = productsArray.map((product) => ({
      ...product,
      tabelaPrecoDesconto: selectedTabelaPreco,
      quantidade: totalAmount,
    }));

    const checkoutData = transformProductJsonToCheckout(
      updatedProductsArray,
      clienteInfo,
      currentUser,
      selectedTabelaPreco.tabelapreco
    );

    const response = await api.post("/i-checkout-tmp/lista", checkoutData);

    if (response.status !== 201) {
      throw new Error("Houve uma falha ao enviar os produtos para o carrinho.");
    }

    if (response.status === 201) {
      clearStorage();
    }

    return response.data;
  };

  const mutation = useMutation(createPreOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries("checkout");
      onProductsSent();
      sessionStorage.removeItem("@Express:SessionProducts");
      clearSelectedProducts();

      setIsSendingToCart(false);
    },
    onError: (error) => {
      toast.error(error.message);

      setIsSendingToCart(false);
    },
  });

  return (
    <>
      <Button
        startIcon={icon && <ShoppingCart />}
        color="warning"
        sx={{ my: isMobile && 2 }}
        fullWidth={isMobile}
        disabled={isSendingToCart}
        onClick={() =>
          mutation.mutate({
            products,
            clienteInfo,
            loggedUser,
            selectedTabelaPreco,
          })
        }
        {...props}
      >
        {label}
      </Button>
    </>
  );
};
