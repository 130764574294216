import React from "react";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { CustomersList } from "./components/CustomersList";
import { DefaultLayout } from "../../../layouts/DefaultLayout";

const Customers = () => {
  const { referencia } = useParams();

  const breadcrumbs = referencia
    ? [
        { url: "/favoritos", label: "Favoritos" },
        { url: "/clientes", label: "Clientes" },
      ]
    : [
        { url: "/lista/produtos", label: "Produtos" },
        { url: "/clientes", label: "Clientes" },
      ];

  return (
    <DefaultLayout breadcrumbs={breadcrumbs}>
      <div className="card card-default color-palette-box">
        <div className="card-body">
          <CustomersList />
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Customers;
