import { Box, IconButton } from "@mui/material";
import { DialogConfirmation } from "../../../../components/DialogConfirmation";
import { ProductInfo } from "../../ProdutoDetalhe/ProductInfo";
import { ProductTable } from "../../ProdutoDetalhe/ProductTable/ProductTable";

import CloseIcon from "@mui/icons-material/Close";

export const DialogGrade = ({ modal, product }) => {
  if (!product) {
    return;
  }

  return (
    <DialogConfirmation
      open={modal.modalIsOpen}
      title={
        modal.title
          ? modal.title
          : `Produto: ${product.linha} - Referência: ${product.referencia}`
      }
      onClose={modal.handleCloseModal}
      onConfirm={modal.handleConfirm}
      isLoading={modal.isLoading}
      closeButton={
        <IconButton onClick={modal.handleCloseModal}>
          <CloseIcon />
        </IconButton>
      }
    >
      <Box>
        <ProductTable initialProductData={product} />
      </Box>
    </DialogConfirmation>
  );
};
