import {
  Box,
  Button,
  FormControl,
  Radio,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { Fragment } from "react";
import { documentMask } from "../../../../utils/documentMask";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";

export const CustomerCard = ({ item, selectedCustomer, onChangeCustomer }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { referencia } = useParams();

  return (
    <Fragment key={item.id}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          className="callout callout-info"
          onClick={onChangeCustomer}
          sx={{
            width: { lg: "100%" },
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: { lg: 80, xs: 30 },
            }}
          >
            <FormControl>
              <Radio
                value={item.cliente.fantasia}
                checked={selectedCustomer === item}
              />
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: isMobile ? "1fr" : "1fr 1fr 1fr",
              flexDirection: { lg: "row", xs: "column" },
              justifyContent: isMobile ? "center" : "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography variant="subtitle2" textAlign={"center"}>
              {item.cliente.fantasia || item.cliente.razao}
            </Typography>

            <Typography
              variant="subtitle2"
              textAlign={"center"}
              color={grey[600]}
              letterSpacing={0.4}
            >
              {item.cliente && documentMask(item.cliente.cnpjCpf)}
            </Typography>

            <Typography variant="overline" textAlign={"center"}>
              {item.cliente.endereco}
            </Typography>
          </Box>
        </Box>
      </Box>

      {selectedCustomer === item && (
        <Box
          sx={{
            display: "grid",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: isMobile && 2,
            mb: 1,
          }}
        >
          <Link to={referencia ? `/produto/${referencia}` : "/grade/produtos"}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                color: "#fff",
                "&:hover": {
                  opacity: 2,
                },
              }}
              fullWidth={isMobile}
            >
              Avançar
            </Button>
          </Link>
        </Box>
      )}
    </Fragment>
  );
};
