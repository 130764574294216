import { useState, forwardRef, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import { fetchStandardGrade } from "../services/gradeServices";
import { useQuery } from "react-query";
import { extractGrade } from "../../../../utils/productWithGrade";
import { Grade } from "../../../../components/GradeExpress/Grade";
import { grey } from "@mui/material/colors";

export const GradeSelect = forwardRef(
  ({ gradeOptions, selectedGrade, selectedType, onSelectGrade }, ref) => {
    const [showGradeModal, setShowGradeModal] = useState(false);

    const handleChange = (event) => {
      onSelectGrade(event.target.value);

      setShowGradeModal(!showGradeModal);
    };

    const handleCloseModal = () => {
      setShowGradeModal(!showGradeModal);
    };

    const { data, isFetching, refetch } = useQuery(
      ["grade", selectedType],
      async () => {
        const response = await fetchStandardGrade(selectedType, selectedGrade);

        const formattedGrade = extractGrade(response);

        return formattedGrade;
      },
      { enabled: !!selectedGrade }
    );

    useEffect(() => {
      refetch();
    }, [showGradeModal]);

    return (
      <>
        <FormControl sx={{ minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small-label">Grade</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            label="Grade"
            value={selectedGrade || ""}
            onChange={handleChange}
            inputRef={ref}
          >
            {gradeOptions &&
              gradeOptions[selectedType]?.map(({ qtde }) => (
                <MenuItem
                  value={qtde}
                  key={qtde}
                  aria-owns={open ? "mouse-over-popover" : undefined}
                  aria-haspopup="true"
                >
                  {qtde}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        {showGradeModal && (
          <>
            <Dialog open={showGradeModal} onClose={handleCloseModal}>
              <DialogContent sx={{ minWidth: isFetching && 399 }}>
                <Typography
                  textAlign="center"
                  variant="h6"
                  mb={2}
                  color={grey[800]}
                >
                  {isFetching ? (
                    <Skeleton variant="text" />
                  ) : (
                    `Grade ${selectedGrade}`
                  )}
                </Typography>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {isFetching ? (
                    <Skeleton variant="rectangular" width={300} height={80} />
                  ) : (
                    data?.map(({ nro, estoque }) => (
                      <Grade nro={nro} estoque={estoque} key={nro} />
                    ))
                  )}
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseModal}>Fechar</Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </>
    );
  }
);
