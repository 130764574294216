import { Text, View } from "@react-pdf/renderer";
import { styles } from "./styles";

export const InvoiceTableHead = ({ productSizes }) => (
  <View style={{ width: "100%", flexDirection: "row", marginTop: 10 }}>
    <View style={[styles.theader]}>
      <Text>Descrição</Text>
    </View>
    <View style={styles.theader}>
      <Text>Referência</Text>
    </View>
    <View style={styles.theader}>
      <Text>NCM</Text>
    </View>
    <View style={[styles.theader, styles.theader2]}>
      <Text>Grade</Text>
    </View>

    <View style={styles.theader}>
      <Text>Desconto</Text>
    </View>
    <View style={styles.theader}>
      <Text>Quantidade</Text>
    </View>
    {/* <View style={styles.theader}>
      <Text>Cxs</Text>
    </View> */}
    <View style={styles.theader}>
      <Text>Unitário</Text>
    </View>
    <View style={styles.theader}>
      <Text>Total</Text>
    </View>
  </View>
);
