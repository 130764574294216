import React, { useMemo, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import api from "../../../../services/api";
import { productWithGrade } from "../../../../utils/productWithGrade";
import { Box, CircularProgress, TextField, Typography } from "@mui/material";
import { ProductCardFavoritos } from "./ProductCardFavoritos.js/ProductCardFavoritos";
import { useLoggedUser } from "../../../../contexts/LoggedUserContext";

export const FavoritosList = () => {
  const { loggedUser, currentUser } = useLoggedUser();
  const [searchValue, setSearchValue] = useState("");

  const fetchFavoritesList = async () => {
    const response = await api.get(`/favoritos/usuario/${currentUser}`);
    return response.data.records.map((record) => ({
      ...record,
      estoque: productWithGrade(record.estoque, new Date()),
    }));
  };

  const { data: favoritesList, isLoading } = useQuery(
    "favoritesList",
    fetchFavoritesList
  );

  const queryClient = useQueryClient();

  const mutation = useMutation(
    (favorite) =>
      api.delete(`/favoritos/${favorite.referencia}/${currentUser}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("favoritesList");
      },
    }
  );

  const handleRemoveFavorite = (favorite) => {
    mutation.mutate(favorite);
  };

  const handleChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  const filteredFavoritesList = useMemo(() => {
    const lowerSearchValue = searchValue.toLowerCase();

    return favoritesList?.filter((favorite) => {
      const referenciaMatch = favorite.referencia
        .toLowerCase()
        .includes(lowerSearchValue);
      const linhaMatch = favorite.linha
        .toLowerCase()
        .includes(lowerSearchValue);
      const corMatch = favorite.cor.toLowerCase().includes(lowerSearchValue);

      return referenciaMatch || linhaMatch || corMatch;
    });
  }, [favoritesList, searchValue]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
          width: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">Lista de Favoritos</Typography>

        <TextField
          label="Buscar Produto"
          placeholder="Referencia/Linha/Cor"
          value={searchValue}
          onChange={handleChangeSearchValue}
        />
      </Box>

      {!isLoading && filteredFavoritesList?.length > 0 ? (
        filteredFavoritesList
          .sort((product) => {
            if (product.estoque.length === 0) {
              return 1;
            }

            return -1;
          })
          .map((favorite) => (
            <Box key={favorite.id} mt={2}>
              <ProductCardFavoritos
                product={favorite}
                variant="extended"
                onDelete={() => handleRemoveFavorite(favorite)}
              />
            </Box>
          ))
      ) : (
        <Typography textAlign="center" mt={4}>
          Não existem favoritos.
        </Typography>
      )}
    </>
  );
};
