import { Button, IconButton, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";

export const QuantityControl = ({ value, onIncrement, onDecrement }) => {
  return (
    <Box
      border={1}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      borderRadius={"5px"}
      borderColor={grey[500]}
      padding={"5px"}
      minWidth={65}
      height={30}
    >
      <IconButton
        variant="outlined"
        onClick={onDecrement}
        size="small"
        color="primary"
        disabled={value === 0}
      >
        -
      </IconButton>
      <Typography color="black">{value}</Typography>
      <IconButton
        variant="outlined"
        onClick={onIncrement}
        size="small"
        color="primary"
      >
        +
      </IconButton>
    </Box>
  );
};
