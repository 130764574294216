import { useEffect, useState } from "react";
import { FixedSizeList as List } from "react-window";
import {
  Box,
  Button,
  CircularProgress,
  Link,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import {
  extractGradePadrao,
  extractGradePadraoWithReserved,
} from "../../../../utils/productWithGrade";

import { GradeSelect } from "./GradeSelect";

import {
  fetchStandardGrade,
  fetchProductTypes,
  loadedTransformedProducts,
  loadProductsFromSession,
} from "../services/gradeServices";

import { ProductTypeSelect } from "./ProductTypeSelect";

import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { useCallback } from "react";
import { SendToCheckout } from "./SendToCheckout";

import { useRef } from "react";
import { EmptyItems } from "./EmptyItemsWarning";

import { DialogConfirmation } from "../../../../components/DialogConfirmation";
import { PageAndCustomerInfo } from "../../../../components/PageAndCustomerInfo";

import { GradeCustomizada } from "./GradeCustomizada";
import { ProductList } from "./ProductList";

export const ProdutosSelecionadosGrid = () => {
  const [products, setProducts] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [gradeOptions, setGradeOptions] = useState([]);

  const [selectedType, setSelectedType] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);

  const [selectedProductIds, setSelectedProductIds] = useState(new Set());

  const [selectedGradeItem, setSelectedGradeItem] = useState();

  const [isLoading, setIsLoading] = useState(true);
  const [openConfirmationModal, setConfirmationModal] = useState(false);

  const productTypeSelectRef = useRef();
  const gradeRef = useRef();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [sessionProducts, setSessionProducts] = useState([]);

  const updateSessionProducts = () => {
    const sessionProducts = sessionStorage.getItem("@Express:SessionProducts");
    const parsedSessionProducts = JSON.parse(sessionProducts) || [];
    setSessionProducts(parsedSessionProducts);
  };

  useEffect(() => {
    const updateSessionProducts = () => {
      const loadedProducts = loadProductsFromSession(
        sessionStorage.getItem("@Express:SessionProducts")
      );
      setSessionProducts(loadedProducts);
    };

    updateSessionProducts();

    window.addEventListener("storage", updateSessionProducts);

    return () => {
      window.removeEventListener("storage", updateSessionProducts);
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const loadedProducts = await loadedTransformedProducts(sessionProducts);
        setProducts(loadedProducts);
        setIsLoading(false);
      } catch (err) {
        console.log("Error occured when loading products");
      }
    })();
  }, [sessionProducts]);

  useEffect(() => {
    const fetchInitialData = async () => {
      const fetchedProductTypes = await fetchProductTypes();
      setProductTypes(fetchedProductTypes);
    };

    fetchInitialData();
  }, [selectedType]);

  useEffect(() => {
    if (selectedType && selectedGrade) {
      const fetchGradeItem = async () => {
        const fetchedGradeItem = await fetchStandardGrade(
          selectedType,
          selectedGrade
        );
        setSelectedGradeItem(fetchedGradeItem);
      };

      fetchGradeItem();
    }
  }, [selectedType, selectedGrade]);

  useEffect(() => {
    if (selectedType) {
      const filteredOptions = productTypes
        .filter((item) => item.tipo === selectedType)
        .map((item) => {
          if (
            Array.isArray(item.qtdegrade) &&
            typeof item.qtdegrade[0] === "string"
          ) {
            return {
              tipo: item.tipo,
              qtdegrade: item.qtdegrade[0]
                .split(",")
                .map((qtde) => ({ qtde: Number(qtde.trim()) })),
            };
          }
          return { tipo: item.tipo, qtdegrade: [] };
        })
        .reduce((acc, item) => {
          if (!acc[item.tipo]) {
            acc[item.tipo] = [];
          }
          acc[item.tipo] = acc[item.tipo].concat(item.qtdegrade);
          return acc;
        }, {});

      setGradeOptions(filteredOptions);
    }
  }, [selectedType, productTypes]);

  const toggleProductSelection = useCallback(
    (product) => {
      if (
        product.gradePadrao.length === 0 &&
        selectedType === "CINTO" &&
        selectedType !== null &&
        product.tipoProduto !== "CINTO"
      ) {
        return toast.info(
          "A grade não pode ser atribuída a um produto de tipo diferente.",
          {
            theme: "colored",
          }
        );
      }

      if (
        product.gradePadrao.length === 0 &&
        product.tipoProduto === "CINTO" &&
        selectedType !== null &&
        selectedType !== "CINTO"
      ) {
        return toast.info(
          "A grade não pode ser atribuída a um produto de tipo diferente."
        );
      }

      if (
        product.gradePadrao.length === 0 &&
        selectedType === "MOCHILA" &&
        selectedType !== null &&
        product.tipoProduto !== "MOCHILA"
      ) {
        return toast.info(
          "A grade não pode ser atribuída a um produto de tipo diferente.",
          {
            theme: "colored",
          }
        );
      }

      if (
        product.gradePadrao.length === 0 &&
        product.tipoProduto === "MOCHILA" &&
        selectedType !== null &&
        selectedType !== "MOCHILA"
      ) {
        return toast.info(
          "A grade não pode ser atribuída a um produto de tipo diferente."
        );
      }

      const updatedProducts = products.map((p) =>
        p.id === product.id
          ? {
              ...p,
              gradePadrao: extractGradePadraoWithReserved(
                selectedGradeItem,
                product.gradeProduto,
                product.reservado
              ),
            }
          : p
      );

      sessionStorage.setItem(
        "@Express:SessionProducts",
        JSON.stringify(updatedProducts)
      );

      setProducts(updatedProducts);
    },
    [products, selectedType, selectedGradeItem]
  );

  const applyGradePadrao = () => {
    const updatedProducts = products.map((product) => {
      if (
        (selectedType === "CINTO" && product.tipoProduto !== "CINTO") ||
        (product.tipoProduto === "CINTO" && selectedType !== "CINTO") ||
        (selectedType === "MOCHILA" && product.tipoProduto !== "MOCHILA") ||
        (product.tipoProduto === "MOCHILA" && selectedType !== "MOCHILA") ||
        product.gradePadrao.length > 0
      ) {
        return product;
      } else {
        return {
          ...product,
          gradePadrao: selectedGradeItem
            ? extractGradePadraoWithReserved(
                selectedGradeItem,
                product.gradeProduto,
                product.reservado
              )
            : product.gradePadrao,
        };
      }
    });

    setProducts(updatedProducts);

    sessionStorage.setItem(
      "@Express:SessionProducts",
      JSON.stringify(updatedProducts)
    );
  };

  const handleSelectType = (type) => {
    setSelectedType(type);
    setSelectedGrade(null);
  };

  const handleSelectGrade = (grade) => {
    setSelectedGrade(grade);
    setSelectedProductIds(new Set());
  };

  const groupProductsBySemana = (products) => {
    return products.reduce((groupedProducts, product) => {
      const key = product.semana;
      if (!groupedProducts[key]) {
        groupedProducts[key] = [];
      }
      groupedProducts[key].push(product);
      return groupedProducts;
    }, {});
  };

  const groupedProducts = groupProductsBySemana(products);

  const notifySucessOnCheckout = () => {
    if (selectedProductIds.size > 0 || products.length > 0) {
      toast.success(
        `${
          selectedProductIds.size > 0
            ? selectedProductIds.size
            : products.length
        } itens enviados para o carrinho`,
        {
          autoClose: 3000,
        }
      );

      updateSessionProducts();

      setSelectedProductIds(new Set());

      setConfirmationModal(true);
    } else {
      // toast.info("Selecione itens para enviar para o carrinho.", {
      //   theme: "colored",
      // });
    }
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="60vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <ToastContainer />
      {!isLoading && products.length === 0 ? (
        <EmptyItems message="Sua lista de produtos está vazia.">
          <Link href="/checkout" sx={{ width: "100%" }}>
            <Button
              fullWidth
              sx={{ mt: 4 }}
              variant="contained"
              color="warning"
            >
              Ir para o carrinho de compras
            </Button>
          </Link>
          <Link href="/lista/produtos" sx={{ width: "100%" }}>
            <Button fullWidth sx={{ mt: 2 }} variant="contained" color="info">
              Continuar Comprando
            </Button>
          </Link>
        </EmptyItems>
      ) : (
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <PageAndCustomerInfo sectionTitle="Escolher Grade" />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Box
              sx={{
                display: isMobile ? "grid" : "flex",
                flexDirection: isMobile ? "column" : "row",
                gridTemplateColumns: "1fr 1fr",
                gap: 2,
                width: "100%",
                mt: isMobile && 2,
                width: isMobile ? "100%" : "auto",
              }}
            >
              <ProductTypeSelect
                productTypes={productTypes}
                selectedType={selectedType}
                onSelectType={handleSelectType}
                ref={productTypeSelectRef}
              />

              <GradeSelect
                selectedType={selectedType}
                gradeOptions={gradeOptions}
                selectedGrade={selectedGrade}
                onSelectGrade={handleSelectGrade}
                ref={gradeRef}
              />

              <Button
                variant="outlined"
                onClick={applyGradePadrao}
                disabled={!selectedType || !selectedGrade}
                sx={{
                  gridColumn: isMobile ? "1 / -1" : "auto",
                  width: isMobile ? "100%" : "auto",
                  mb: isMobile && 2,
                }}
              >
                Aplicar grade padrão
              </Button>
            </Box>

            <SendToCheckout
              key={`send-all-${products.length}`}
              label={
                selectedProductIds && selectedProductIds.size > 0
                  ? `Enviar ${selectedProductIds.size} itens para o carrinho`
                  : "Enviar todos para o carrinho"
              }
              variant="contained"
              products={
                selectedProductIds && selectedProductIds.size > 0
                  ? selectedProductIds
                  : products
              }
              onProductsSent={notifySucessOnCheckout}
              clearStorage={updateSessionProducts}
              icon
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "row" : "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              mb: 2,
            }}
          >
            <GradeCustomizada
              products={products}
              refreshStorage={updateSessionProducts}
              typeOptions={productTypes}
            />
          </Box>

          {products.length > 0 && (
            <Paper sx={{ backgroundColor: "#f8f8f8", padding: 3 }}>
              {Object.entries(groupedProducts).map(([semana, products]) => (
                <div key={semana}>
                  <h2>
                    {new Date(semana).toLocaleDateString("pt-BR", {
                      timeZone: "UTC",
                    })}
                  </h2>
                  {products.map((product) => (
                    <ProductList
                      product={product}
                      sessionProducts={products}
                      applyGradeOnClick={toggleProductSelection}
                      refreshStorage={updateSessionProducts}
                      isSelectedGrade={selectedGrade ? false : true}
                    />
                  ))}
                </div>
              ))}
            </Paper>
          )}
        </Box>
      )}

      {openConfirmationModal &&
        (selectedProductIds.size > 0 || products.length > 0) && (
          <DialogConfirmation
            open={openConfirmationModal}
            title="Visualizar carrinho"
            onConfirm={() => {
              window.location.href = "/checkout";
            }}
            onClose={() => setConfirmationModal(false)}
            closeButton
          >
            <Typography variant="subtitle1">
              Deseja ir para o carrinho de compras?
            </Typography>
          </DialogConfirmation>
        )}
    </div>
  );
};
