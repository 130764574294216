import {
  useTheme,
  useMediaQuery,
  Box,
  TextField,
  Grid,
  CircularProgress,
} from "@mui/material";
import { useMemo, useState } from "react";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { CardOrder } from "./CardOrder";
import { Modal } from "@mui/material";
import { OrderDetails } from "./OrderDetails";
import { useLoggedUser } from "../../../../contexts/LoggedUserContext";
import api from "../../../../services/api";
import { EmptyOrdersUI } from "./EmptyOrdersUI";

export const PedidosList = () => {
  const [customerSearch, setCustomerSearch] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { loggedUser, currentUser } = useLoggedUser();

  const { data: checkoutData, isLoading } = useQuery(
    "ExpressPedidosRealizados",
    async () => {
      const response = await api.get(`/checkout/representante/${currentUser}`);

      return response.data.records;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const filterCustomers = (customer, filterValue) => {
    const customerData = {
      razaoCliente: customer.razao?.toLowerCase() || "",
      fantasia: customer.fantasia?.toLowerCase() || "",
      cnpj: customer.cnpjCpf?.toLowerCase() || "",
    };

    return Object.values(customerData).some((value) =>
      value.includes(filterValue.toLowerCase())
    );
  };

  const filteredCustomers = useMemo(() => {
    return checkoutData?.filter((customer) =>
      filterCustomers(customer.clientes, customerSearch)
    );
  }, [checkoutData, customerSearch]);

  const handleModalOpen = () => {
    setModalIsOpen(!modalIsOpen);
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!isLoading && checkoutData.length === 0) {
    return <EmptyOrdersUI />;
  }

  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateAreas: isMobile
            ? `
              'startDate . endDate'
              'search search search'
            `
            : `
          'startDate endDate . .  . . .  .  . search'
        `,
          gap: !isMobile && 2,
        }}
        mb={2}
      >
        <Box gridArea={"startDate"}>
          {/* <CustomDatePicker
            label="Data Inicial"
            selectedDate={startDatePicker.selectedDate}
            handleDateChange={startDatePicker.handleDateChange}
          /> */}
        </Box>

        <Box gridArea={"endDate"}>
          {/* <CustomDatePicker
            label="Data Fim"
            selectedDate={endDatePicker.selectedDate}
            handleDateChange={endDatePicker.handleDateChange}
          /> */}
        </Box>

        <Box gridArea={"search"}>
          <TextField
            label="Buscar por cliente"
            value={customerSearch}
            onChange={(e) => setCustomerSearch(e.target.value)}
            sx={{ minWidth: 300, my: 2 }}
            fullWidth
          />
        </Box>
      </Box>
      <Grid container spacing={1} columns={{ xs: 2, sm: 4, lg: 12 }}>
        {filteredCustomers
          ?.sort(
            (a, b) => new Date(b.dataFaturamento) - new Date(a.dataFaturamento)
          )
          .map((customerOrder) => (
            <Grid
              item
              xs={2}
              md={4}
              key={customerOrder.id}
              onClick={() => setSelectedOrder(customerOrder)}
            >
              <CardOrder
                key={customerOrder.id}
                order={customerOrder}
                selected={selectedOrder}
                openModal={handleModalOpen}
              />
            </Grid>
          ))}
      </Grid>

      {selectedOrder && (
        <Modal
          open={modalIsOpen}
          onClose={handleModalOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "100vw", sm: "80vw" },
              height: { xs: "100vh", sm: "90vh" },
              overflow: "scroll",
              bgcolor: "background.paper",
              boxShadow: 24,
            }}
          >
            <OrderDetails orderDetails={selectedOrder} />
          </Box>
        </Modal>
      )}
    </>
  );
};
