import { Button } from "@mui/material";
import api from "../../../../services/api";
import { useLoggedUser } from "../../../../contexts/LoggedUserContext";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "react-query";
import { useTabelasPrecoContext } from "../../../../contexts/TabelasPreco";

export const ButtonApplyDiscount = ({
  customers,
  discounts,
  clearDiscounts,
}) => {
  const { loggedUser, currentUser } = useLoggedUser();
  const queryClient = useQueryClient();

  const { selectedTabelaPreco } = useTabelasPrecoContext();

  const applyDiscountInSelectedDates = useMutation(
    async (customers) => {
      const promises = customers.map(async (temporaryOrder) => {
        if (discounts.firstDiscount === 0) {
          return toast.warning("Nenhum desconto foi aplicado.");
        }

        const response = await api.post(`/i-checkout-tmp/aplicar-desconto`, {
          reprVendedor: currentUser,
          cliente: temporaryOrder.clientId,
          semanas: temporaryOrder.dates,
          desconto: Number(discounts.firstDiscount),
          segDesconto: Number(discounts.secondDiscount),
        });

        if (response.status === 200) {
          return toast.success("Desconto aplicado com sucesso!");
        }

        return response.data.records;
      });

      await Promise.all(promises);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("checkout");

        clearDiscounts();
      },
    }
  );

  return (
    <Button
      onClick={() => applyDiscountInSelectedDates.mutate(customers)}
      disabled={
        customers.length === 0 || applyDiscountInSelectedDates.isLoading
      }
      color="success"
      size="small"
    >
      Aplicar desconto
    </Button>
  );
};
