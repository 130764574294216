import api from "../../../../services/api";
import { useQuery } from "react-query";

import { ProductCardDefault } from "../../../../components/GradeExpress/Standard/ProductCardDefault";
import { Box, CircularProgress, Grid } from "@mui/material";
import { useBreakpoints } from "../../../../hooks/useBreakpoints";
import {
  productObjectPDF,
  productWithGrade,
  productWithGradeCheckout,
} from "../../../../utils/productWithGrade";

export const PedidosItens = ({ order }) => {
  const isMobile = useBreakpoints("md");

  const fetchOrderItems = async ({ queryKey }) => {
    const [, orderId] = queryKey;
    try {
      const response = await api.get(`/checkout/itens/${orderId}`);
      const productWithFormattedGrade = productObjectPDF(
        response.data.records,
        true
      );
      return productWithFormattedGrade;
    } catch (error) {
      console.error(error);
    }
  };

  const { data: orderItems, isLoading } = useQuery(
    ["orderItems", order.id],
    fetchOrderItems,
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Grid container spacing={2} columns={2}>
      {isLoading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",

            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        orderItems &&
        orderItems.length > 0 &&
        orderItems.map((item) => (
          <Grid item xs={1} key={item.id}>
            <ProductCardDefault
              disableImageCache
              disableApplyGradeButton
              disableLight
              grade={item.gradeProduto}
              product={item}
              finalPrice={item.unitario}
            />
          </Grid>
        ))
      )}
    </Grid>
  );
};
