import { useContext } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { createContext } from "react";

export const ProductContext = createContext({});

export const ProductContextWrapper = ({ children }) => {
  const [produtoSelecionado, setProdutoSelecionado] = useState({});
  const [gradeSelecionada, setGradeSelecionada] = useState([]);

  const [cart, setCart] = useState([]);

  const selecionarProduto = (produto) => {
    setProdutoSelecionado(produto);

    localStorage.setItem(
      "@Express:ProdutoSelecionado",
      JSON.stringify(produto)
    );
  };

  const adicionarProdutoCarrinho = (product) => {
    const { referencia } = product;
    const updatedCart = [...cart];
    const isNewProduct = updatedCart.find(
      (product) => product.referencia === referencia
    );

    if (!isNewProduct) {
      updatedCart.push(product);
    }

    setCart(updatedCart);
  };

  return (
    <ProductContext.Provider
      value={{
        produtoSelecionado,
        selecionarProduto,
        cart,
        adicionarProdutoCarrinho,
        gradeSelecionada,
        setGradeSelecionada,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export const useProductContext = () => {
  return useContext(ProductContext);
};
