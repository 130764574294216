import { createContext, useContext } from "react";

export const LoggedUserContext = createContext();

export const LoggedUserProvider = ({ children }) => {
  const loggedUser = JSON.parse(localStorage.getItem("@Express:User")) || {};

  const currentUser =
    loggedUser.representante || loggedUser.consultor || loggedUser.vendedor;

  const contextValue = {
    loggedUser,
    currentUser,
  };

  return (
    <LoggedUserContext.Provider value={contextValue}>
      {children}
    </LoggedUserContext.Provider>
  );
};

export const useLoggedUser = () => {
  const context = useContext(LoggedUserContext);
  if (!context) {
    throw new Error("useLoggedUser must be used within a LoggedUserProvider");
  }
  return context;
};
