import { InputAdornment, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ptBR } from "@mui/x-date-pickers/locales";
import dayjs from "dayjs";

import "dayjs/locale/pt-br";

export const ExpirationDatePicker = ({
  label,
  currentDate,
  handleDateChange,
  ...props
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"pt-br"}>
      <DatePicker
        value={dayjs(currentDate)}
        onChange={handleDateChange}
        label="Expira em"
        renderInput={(params) => <TextField {...params} size="small" />}
      />
    </LocalizationProvider>
  );
};
