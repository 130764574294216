import Header from "../../../components/Header";
import { UserIdent } from "../../../components/controles/UserIdent";

import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { CheckoutItems } from "./Components/CheckoutItems";
import { useProductContext } from "../../../contexts/ProductContext";
import { CheckoutProvider } from "../../../contexts/CheckoutContext";
import { DefaultLayout } from "../../../layouts/DefaultLayout";

export const Checkout = () => {
  const breadcrumbs = [
    { label: "Produtos", url: "/lista/produtos" },
    { label: "Clientes", url: "/clientes" },
    {
      label: "Grade Padrão",
      url: `/grade/produtos`,
    },
    { label: "Checkout", url: "/checkout", active: true },
  ];

  return (
    <DefaultLayout breadcrumbs={breadcrumbs} activeBreadcrumb={breadcrumbs[3]}>
      <CheckoutProvider>
        <CheckoutItems />
      </CheckoutProvider>
    </DefaultLayout>
  );
};
