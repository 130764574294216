import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputAdornment,
  IconButton,
  CircularProgress,
  Chip,
  Grid,
} from "@mui/material";

import { DefaultLayout } from "../../layouts/DefaultLayout";
import { useQuery } from "react-query";
import { authenticationApi } from "../../services/api";

import SearchIcon from "@mui/icons-material/Search";

import { formatarData } from "../../utils/utils";
import { UserApprovalDialog } from "./UserApprovalDialog";
import { Close, Person, PermContactCalendar, Store } from "@mui/icons-material";
import { MobileApprovalCard } from "./MobileApprovalCard";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import { UserApprovalTable } from "./UserApprovalTable";
import { isAdmin } from "../../services/auth";
import { PageAndCustomerInfo } from "../../components/PageAndCustomerInfo";

export const UserApproval = () => {
  const [userSettingsModal, setUserSettingsModal] = useState(false);
  const [currentSelectedUser, setCurrentSelectedUser] = useState(null);
  const [searchByUser, setSearchByUser] = useState("");
  const isMobileScreen = useBreakpoints("md");
  const [filterStatus, setFilterStatus] = useState(null);

  const [page, setPage] = useState(0);

  const handleDialogIsOpen = () => {
    setUserSettingsModal(!userSettingsModal);
  };

  const handleFilterStatusChange = (event) => {
    setFilterStatus(event.target.value);
  };

  const { data: usersWaitingApproval, isLoading } = useQuery(
    ["userWaitingApproval"],
    async () => {
      const response = await authenticationApi.get("/autorizacao/1");
      return response.data.records;
    }
  );

  const userRoles = {
    1: { name: "Básico" },
    2: { name: "Médio" },
    3: { name: "Administrador" },
    A: { name: "Não definido" },
    R: { name: "Representante", color: "primary", icon: <Person /> },
    L: { name: "Licenciada", color: "secondary", icon: <Store /> },
    C: { name: "Consultor", color: "warning", icon: <PermContactCalendar /> },
    V: { name: "Vendedor", color: "error", icon: <Person /> },
  };

  if (isLoading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );

  const filteredUsers = usersWaitingApproval
    .filter((user) => {
      if (filterStatus === null) return true;
      return user.status === (filterStatus === "Ativo" ? 1 : 0);
    })
    .filter((user) => {
      if (searchByUser === "") return true;
      return user.codUsuario.nome
        .toLowerCase()
        .includes(searchByUser.toLowerCase());
    });

  const handleChangeSearch = (event) => {
    setPage(0);
    setSearchByUser(event.target.value);
  };

  return (
    <DefaultLayout>
      <PageAndCustomerInfo
        sectionTitle={"Usuários - Express"}
        disableCustomer
      />

      <Grid
        container
        mb={4}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Grid item xs={isMobileScreen ? 6 : 4}>
          <TextField
            fullWidth
            id="search"
            label="Buscar usuário"
            onChange={handleChangeSearch}
            value={searchByUser}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6} textAlign="end">
          <RadioGroup
            row
            aria-label="status"
            name="status"
            value={filterStatus}
            onChange={handleFilterStatusChange}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <FormControlLabel value="Ativo" control={<Radio />} label="Ativo" />
            <FormControlLabel
              value="Inativo"
              control={<Radio />}
              label="Inativo"
            />
            {filterStatus && (
              <IconButton onClick={() => setFilterStatus(null)}>
                <Close />
              </IconButton>
            )}
          </RadioGroup>
        </Grid>
      </Grid>

      {!isMobileScreen && (
        <UserApprovalTable
          users={filteredUsers}
          roles={userRoles}
          handleSelectUser={setCurrentSelectedUser}
          handleDialogIsOpen={handleDialogIsOpen}
          page={page}
          setPage={setPage}
        />
      )}

      {isMobileScreen && (
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr" }}>
          {filteredUsers.map((user) => (
            <MobileApprovalCard
              key={user.id}
              user={user}
              roles={userRoles}
              handleSelectUser={setCurrentSelectedUser}
              handleDialogIsOpen={handleDialogIsOpen}
            />
          ))}
        </Box>
      )}

      {userSettingsModal && (
        <UserApprovalDialog
          open={userSettingsModal}
          user={currentSelectedUser}
          handleDialogIsOpen={handleDialogIsOpen}
        />
      )}
    </DefaultLayout>
  );
};
