import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import Logo from "../../assets/logo-democrata.png";
import api, { authenticationApi } from "../../services/api";
import Imagelogin from "../../assets/sapatoDemocrata.png";
import { REMEMBER_CONTROLL, login } from "../../services/auth";

import { Imagem } from "./styles";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Hidden,
} from "@mui/material";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const SignIn = ({ history }) => {
  const [usuario, setUsuario] = useState("");
  const [senha, setSenha] = useState("");
  const [isSendingLogin, setIsSendingLogin] = useState(false);
  const [error, setError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const handleSignIn = async (e) => {
    e.preventDefault();

    try {
      if (!usuario || !senha) {
        return toast.error("Usuário e senha são obrigatórios.");
      }

      setIsSendingLogin(true);

      const response = await authenticationApi.post("/auth", {
        usuario,
        senha,
        sistema: 1,
      });

      login(
        response.data.records[0].token,
        response.data.records[0].id,
        rememberMe ? { usuario, senha } : null
      );

      if (response.status === 200) {
        localStorage.setItem(
          "@Express:User",
          JSON.stringify({
            ...response.data.records[0],
            usuario,
            senha,
          })
        );

        return history.push("/lista/produtos");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.developerMessage);
    } finally {
      setIsSendingLogin(false);
    }
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("@Express:User"));

    if (userData) {
      setUsuario(userData.usuario);
      setSenha(userData.senha);
      setRememberMe(true);
    }
  }, []);

  const handleChange = (e) => {
    const { checked } = e.target;
    setRememberMe(checked);

    if (!checked) {
      setUsuario("");
      setSenha("");
      localStorage.removeItem("@Express:User");
    }

    localStorage.setItem(REMEMBER_CONTROLL, JSON.stringify(checked));
  };

  return (
    <div className="d-lg-flex half">
      <ToastContainer />

      <Hidden mdDown>
        <div className="bg order-1 order-md-2">
          <Imagem src={Imagelogin} alt="" />
        </div>
      </Hidden>

      <div className="contents order-2 order-md-1">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-7">
              <div className=" text-center mb-4">
                <img
                  src={Logo}
                  alt="Logo"
                  style={{ margin: "auto", width: "100%" }}
                />
              </div>

              <form className="form" onSubmit={handleSignIn}>
                <div className="form-group first">
                  <label htmlFor="username">Usuário</label>
                  <div className="input-group mb-3">
                    <input
                      type="Usuario"
                      className="form-control"
                      placeholder="Usuário"
                      value={usuario}
                      onChange={(e) => setUsuario(e.target.value)}
                    />
                    <div className="input-group-append">
                      <div
                        className="input-group-text"
                        style={{ background: "#FFFFFF", border: "none" }}
                      >
                        <span className="fas fa-user" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group last mb-3">
                  <label htmlFor="password">Senha</label>
                  <div className="input-group mb-3">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Senha"
                      value={senha}
                      onChange={(e) => setSenha(e.target.value)}
                    />
                    <div className="input-group-append">
                      <div
                        className="input-group-text"
                        style={{ background: "#ffff", border: "none" }}
                      >
                        <span className="fas fa-key" />
                      </div>
                    </div>
                  </div>
                </div>

                <FormControlLabel
                  control={
                    <Checkbox
                      value="remember"
                      color="primary"
                      checked={rememberMe}
                      onChange={handleChange}
                    />
                  }
                  label="Lembrar Senha"
                />

                <button
                  type="submit"
                  disabled={isSendingLogin}
                  className="btn btn-block btn-dark btn-primary"
                >
                  Acessar
                </button>
              </form>

              <Divider variant="middle" sx={{ mt: 4 }} />

              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <Link to="/cadastro">
                  <Button variant="text">Solicitar acesso</Button>
                </Link>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SignIn);
