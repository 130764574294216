import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { currencyFormatter } from "../../../../utils/currencyFormatter";
import { useState } from "react";
import { grey } from "@mui/material/colors";
import { Link } from "react-router-dom";

import FeedIcon from "@mui/icons-material/Feed";
import { useQuery } from "react-query";
import { useTabelasPrecoContext } from "../../../../contexts/TabelasPreco";
import api from "../../../../services/api";
import { useCheckout } from "../../../../contexts/CheckoutContext";
import { DiscountFields } from "./DiscountFields";
import { ButtonApplyDiscount } from "./ButtonApplyDIscount";
import { useLoggedUser } from "../../../../contexts/LoggedUserContext";

import { ButtonRemoveDiscount } from "./ButtonRemoveDiscount";

export const SummaryBox = ({
  selectedDates,
  customerProducts,
  orderConfirmation,
}) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down(1300));

  const { selectedCondPgto, setSelectedCondPgto } = useCheckout();
  const { selectedTabelaPreco } = useTabelasPrecoContext();

  const [discounts, setDiscounts] = useState({
    firstDiscount: 0.0,
    secondDiscount: 0.0,
  });

  const [isCreatingOrder, setIsCreatingOrder] = useState(false);

  const [isSummaryVisible, setIsSummaryVisible] = useState(false);

  const generateFinalOrder = async () => {
    setIsCreatingOrder(true);
    await orderConfirmation();
    setIsCreatingOrder(false);
  };

  const clearDiscounts = () => {
    setDiscounts({
      firstDiscount: 0.0,
      secondDiscount: 0.0,
    });
  };

  const handleDiscountValues = (event) => {
    const input = event.target.value;
    const numericInput = input.replace(/[^\d.]/g, "");
    const discountLimit = event.target.name === "secondDiscount" ? 6 : 5;

    if (numericInput > discountLimit) {
      return setDiscounts({
        ...discounts,
        [event.target.name]: discountLimit,
      });
    }

    setDiscounts({
      ...discounts,
      [event.target.name]: numericInput,
    });
  };

  const formattedTabelaPreco =
    selectedDates.length > 0 && selectedDates[0].tabelaPreco.split(" ")[0];

  const disableDiscountForSellers =
    selectedDates.length > 0 && selectedDates[0].tabelaPreco.startsWith("BRL");

  const { data, isLoading } = useQuery(
    ["condPgto", formattedTabelaPreco],
    async () => {
      const response = await api.get(
        `/condPgto/tabelaPreco/${formattedTabelaPreco}`
      );

      return response.data.records;
    },
    {
      enabled: !!formattedTabelaPreco,
    }
  );

  const handlePaymentCondition = (event) => {
    setSelectedCondPgto(event.target.value);
  };

  const getTotalQuantity = () => {
    return selectedDates.reduce((accumulator, dateEntry) => {
      const clientProducts = customerProducts.find(
        (cliente) => cliente.cliente.cnpjCpf === dateEntry.clientId
      );

      const productsForAllSelectedDates = dateEntry.dates.reduce(
        (dateAccumulator, date) => {
          const productsForDate = clientProducts
            ? clientProducts.itens.filter((product) => product.entrega === date)
            : [];

          return (
            dateAccumulator +
            productsForDate
              .flatMap((product) => product.gradeProduto)
              .reduce((sum, grade) => sum + grade.estoque, 0)
          );
        },
        0
      );

      return accumulator + productsForAllSelectedDates;
    }, 0);
  };

  const totalQuantity = getTotalQuantity();

  const getTotalPrice = () => {
    return selectedDates.reduce((accumulator, dateEntry) => {
      const clientProducts = customerProducts.find(
        (cliente) => cliente.cliente.cnpjCpf === dateEntry.clientId
      );

      const valorTotal = clientProducts?.valorTotal || 0;

      return accumulator + valorTotal;
    }, 0);
  };

  const totalAfterDisc = getTotalPrice();

  const toggleSummaryVisibility = () => {
    setIsSummaryVisible((prevVisible) => !prevVisible);
  };

  const disableTable =
    selectedTabelaPreco && selectedTabelaPreco?.tabelapreco?.startsWith("BRL");

  const summaryLabels = {
    primary: [
      { label: "Total de Pedidos", value: selectedDates.length },
      { label: "Quantidade", value: totalQuantity },
    ],
    secondary: [
      { label: "Total", value: currencyFormatter(totalAfterDisc) },
      {
        value: data && data.length > 0 && (
          <FormControl fullWidth sx={{ mt: 1 }} key="condição-pagamento">
            <InputLabel id="payment-condition-label">
              Condição de Pagamento
            </InputLabel>
            <Select
              labelId="payment-condition-label"
              id="payment-condition-select"
              value={selectedCondPgto}
              label="Condição de Pagamento"
              onChange={handlePaymentCondition}
            >
              {data.map((item) => (
                <MenuItem
                  key={item.condicaoPagamento.condicao}
                  value={item.condicaoPagamento.condicao}
                >
                  {item.condicaoPagamento.descricao}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ),
      },
      {
        value: !disableTable && selectedDates.length > 0 && (
          <DiscountFields
            fields={selectedDates}
            discounts={discounts}
            handleChangeDiscounts={handleDiscountValues}
          />
        ),
      },
    ],
  };

  return isMobile || isMediumScreen ? (
    <>
      <Box
        sx={{
          zIndex: 1000,
          width: "100%",
          position: "fixed",
          bottom: 0,
          right: 0,
          left: 0,
        }}
      >
        <Slide
          direction="right"
          in={isSummaryVisible && selectedDates.length > 0}
          mountOnEnter
          unmountOnExit
        >
          <Box
            sx={{
              bgcolor: "#f8f8f8",
              boxShadow: 5,
              borderRadius: 2,
              px: "14vw",
              py: 2,
            }}
          >
            <h3>Total Geral</h3>

            {summaryLabels.primary.map((item) => (
              <SummaryInfoBox
                key={item.label}
                label={item.label}
                value={item.value}
                isMobile={isMobile}
              />
            ))}

            <Divider sx={{ my: 3 }} />

            {summaryLabels.secondary.map((item) => (
              <SummaryInfoBox
                key={item.label}
                label={item.label}
                value={item.value}
                isMobile={isMobile}
              />
            ))}

            {selectedDates.length > 0 && (
              <Grid container xs={12} my={2}>
                <Grid item xs={6}>
                  <ButtonApplyDiscount
                    color="success"
                    customers={selectedDates}
                    discounts={discounts}
                  />
                </Grid>
                <Grid item xs={6} container justifyContent="flex-end">
                  <ButtonRemoveDiscount customers={selectedDates} />
                </Grid>
              </Grid>
            )}

            <Button
              variant="contained"
              color="primary"
              disabled={
                selectedDates.length === 0 ||
                !selectedCondPgto ||
                isCreatingOrder
              }
              onClick={generateFinalOrder}
              fullWidth
            >
              Gerar {selectedDates.length > 0 && selectedDates.length} Pedido(s)
            </Button>

            <Link to="/lista/produtos">
              <Button
                color="primary"
                variant="outlined"
                sx={{ mt: 2 }}
                fullWidth
              >
                Continuar comprando
              </Button>
            </Link>
          </Box>
        </Slide>
      </Box>
      <Slide
        direction="up"
        in={selectedDates.length > 0}
        mountOnEnter
        unmountOnExit
      >
        <Fab
          variant={isSummaryVisible ? "circular" : "extended"}
          aria-label={isSummaryVisible ? "close summary" : "open summary"}
          onClick={toggleSummaryVisibility}
          color="secondary"
          sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(2),
            right: (theme) => theme.spacing(2),
          }}
        >
          <FeedIcon
            sx={{
              mr: !isSummaryVisible && 1,
              transform: isSummaryVisible ? "rotate(45deg)" : "rotate(0deg)",
            }}
          />
          {isSummaryVisible ? null : "Ver Resumo"}
        </Fab>
      </Slide>
    </>
  ) : (
    <Box
      sx={{
        position: "fixed",
        left: "70%",
        minWidth: 330,
        maxWidth: 330,
        overflowY: "auto",

        p: 2,
      }}
      className="card"
    >
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 400,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {!isLoading && (
        <>
          <h3>Total Geral</h3>

          {summaryLabels.primary.map((item) => (
            <SummaryInfoBox
              key={item.label}
              label={item.label}
              value={item.value}
            />
          ))}

          <Divider sx={{ my: 1 }} />

          {summaryLabels.secondary.map((item) => (
            <SummaryInfoBox
              key={item.label}
              label={item.label}
              value={item.value}
            />
          ))}

          {!disableDiscountForSellers && selectedDates.length > 0 && (
            <Grid container xs={12} my={2}>
              <Grid item xs={6}>
                <ButtonApplyDiscount
                  color="success"
                  customers={selectedDates}
                  clearDiscounts={clearDiscounts}
                  discounts={discounts}
                />
              </Grid>
              <Grid item xs={6} container justifyContent="flex-end">
                <ButtonRemoveDiscount customers={selectedDates} />
              </Grid>
            </Grid>
          )}

          <Button
            color="primary"
            variant="contained"
            onClick={generateFinalOrder}
            sx={{ mt: 2 }}
            disabled={
              selectedDates.length === 0 || !selectedCondPgto || isCreatingOrder
            }
            fullWidth
          >
            Gerar {selectedDates.length > 0 && selectedDates.length} Pedido(s)
          </Button>

          <Link to="/lista/produtos">
            <Button color="primary" variant="outlined" sx={{ mt: 2 }} fullWidth>
              Continuar comprando
            </Button>
          </Link>
        </>
      )}
    </Box>
  );
};

const SummaryInfoBox = ({ label, value, isMobile }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        my: isMobile ? 2 : 1,
      }}
    >
      <Typography color={grey[800]}>{label}</Typography>
      {typeof value === "object" ? (
        value
      ) : (
        <Typography color={grey[700]}>{value}</Typography>
      )}
    </Box>
  );
};
