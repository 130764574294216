import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 20,
    paddingLeft: 40,
    paddingRight: 40,
    lineHeight: 1.5,
    flexDirection: "column",
  },

  spaceBetween: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#3E3E3E",
  },

  titleContainer: { flexDirection: "row", marginTop: 10 },

  logo: { width: 90 },

  reportTitle: { fontSize: 16, textAlign: "center" },

  addressTitle: { fontSize: 11, fontStyle: "bold" },
  textBold: { fontWeight: "bold" },

  invoice: { fontWeight: "bold", fontSize: 20 },

  invoiceNumber: { fontSize: 11, fontWeight: "bold" },

  address: { fontWeight: 400, fontSize: 10 },

  headerSection: {
    marginTop: 20,
    border: "2px solid whitesmoke",
    width: "100%",
    padding: 8,
    fontSize: 12,
  },

  headerSectionItem: {
    marginTop: 7,
    fontSize: 10,
  },

  theader: {
    marginTop: 20,
    fontSize: 10,
    fontStyle: "bold",
    paddingTop: 4,
    paddingLeft: 7,
    flex: 1,
    height: 20,
    backgroundColor: "#DEDEDE",
    borderColor: "whitesmoke",
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },

  theader2: { flex: 2, borderRightWidth: 0, borderBottomWidth: 1 },

  tbody: {
    fontSize: 9,
    paddingTop: 4,
    paddingLeft: 7,
    flex: 1,
    borderColor: "whitesmoke",
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
  },

  total: {
    fontSize: 12,
    fontWeight: "bold",
    paddingTop: 4,
    paddingLeft: 7,
    flex: 1.5,
    borderColor: "whitesmoke",
    borderLeftWidth: 1,
    borderBottomWidth: 1,
  },

  tbody2: { flex: 2, borderRightWidth: 1 },

  gradeItemHeader: {
    fontSize: 12,
    padding: 2,
    border: "1px solid #DEDEDE",
    borderRadius: "2px",
    backgroundColor: "#e8e8e8",
    minWidth: 20,
    textAlign: "center",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  gradeItemContent: {
    fontSize: 12,
    padding: 2,
    minWidth: 20,

    border: "1px solid #DEDEDE",
    borderRadius: "2px",
    textAlign: "center",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});
