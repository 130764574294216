import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import { documentMask } from "../../utils/documentMask";

export const DocumentDialog = ({
  open,
  documentValue,
  onClose,
  handleChangeDocument,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <TextField
          label="CPF ou CNPJ"
          value={documentValue}
          onChange={handleChangeDocument}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Confirmar</Button>
      </DialogActions>
    </Dialog>
  );
};
