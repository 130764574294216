import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { ProductDateEntry } from "./ProductDateEntry";
import { useBreakpoints } from "../../../../hooks/useBreakpoints";
import { documentMask } from "../../../../utils/documentMask";

export const ProductGroupAccordion = ({
  customerProducts,
  onProductCheck,
  selectedDates,
  clearSelectedDates,
}) => {
  const isMobile = useBreakpoints("md");

  const groupByEntrega = (itens) => {
    return itens.reduce((acc, produto) => {
      if (!acc[produto.entrega]) {
        acc[produto.entrega] = [];
      }
      acc[produto.entrega].push(produto);

      return acc;
    }, {});
  };

  /**
   *
   * @param {Object} items Recebe o Objeto de produto do cliente para realizar os cálculos de resumo do pedido.
   * @returns {Object} Retorna um objeto com quantitdade, volume, % disc, total, R$ disc, e total com valor descontado.
   */
  const calculateOrderSummary = (items) => {
    const totalQuantity = items.reduce((acc, produto) => {
      const gradeEstoqueSum = produto.gradeProduto.reduce(
        (gradeSum, grade) => gradeSum + grade.estoque,
        0
      );
      return acc + gradeEstoqueSum;
    }, 0);
    const totalAmount = items.reduce((acc, produto) => acc + produto.preco, 0);
    const totalVolume = items.length;
    const discountAmt = (20 / 100) * totalAmount;
    const totalAfterDisc = totalAmount - discountAmt;

    return {
      quantity: totalQuantity,
      volume: totalVolume,
      discountPercentage: 20,
      total: totalAmount,
      discountAmount: discountAmt,
      totalAfterDiscount: totalAfterDisc,
    };
  };

  return (
    <Accordion
      key={customerProducts.cliente.cnpjCpf}
      sx={{ width: isMobile ? "100%" : "75%" }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${customerProducts.cliente.cnpjCpf}-content`}
        id={`panel${customerProducts.cliente.cnpjCpf}-header`}
      >
        <Typography color="#000" variant="body1" fontWeight={800}>
          {customerProducts && customerProducts.cliente.fantasia
            ? customerProducts.cliente.fantasia
            : customerProducts.cliente.razao}
        </Typography>
        <Typography
          ml={1}
          variant="subtitle2"
          textAlign="center"
          alignSelf={"center"}
        >
          {documentMask(customerProducts.cliente.cnpjCpf)}
        </Typography>{" "}
      </AccordionSummary>
      <AccordionSummary>
        <Typography color="#000" variant="body1">
          {customerProducts && customerProducts.cliente.razao}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {Object.entries(groupByEntrega(customerProducts.itens)).map(
          ([entrega, produtos]) => (
            <ProductDateEntry
              key={entrega}
              entrega={entrega}
              produtos={produtos}
              selectedDates={selectedDates || []}
              produtosCliente={customerProducts}
              handleProductCheck={onProductCheck}
              calculateOrderSummary={calculateOrderSummary}
              clearSelectedDates={clearSelectedDates}
            />
          )
        )}
      </AccordionDetails>
    </Accordion>
  );
};
