import styled from "styled-components";

export const ExtendedCardContainer = styled.div`
  height: fit-content;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: start;
  padding: 1rem;
  border: 1px solid #adb5bd;
  border-radius: 5px;

  :hover {
    outline: 2px solid #e9e9e9;
  }

  @media (max-width: 1000px) {
    flex-direction: row;
    min-width: 350px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }
`;

export const ExtendedImageContainer = styled.div`
  min-width: 7rem;
  max-width: 7rem;
  height: 8.25rem;
  background-color: #faf5f5;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 768px) {
    min-width: 100%;
    max-width: 100%;
    height: 9rem;
    padding: 1rem;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
`;

export const ExtendedCardContent = styled.div`
  width: 100%;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 1rem;
  }
`;

export const ExtendedCardTitle = styled.p`
  color: #292928;
  margin: 0 0 8px 0;
  padding: 0;
  font-size: 1.2rem;
  font-weight: bold;

  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 576px) {
    width: 255px;
  }

  @media (max-width: 576px) {
    font-size: 1rem;
  }
`;

export const ExtendedCardGridItem = styled.div(({ $customStyle }) => ({
  color: "#000",
  fontSize: "12px",
  backgroundColor: $customStyle?.backgroundColor,
  display: "flex",
  outline: "1px solid #adb5bd",
  justifyContent: "center",
  marginBottom: "0.3rem",
  padding: "0.3rem",

  ...$customStyle,
}));

export const ExtendedCardDescription = styled.p`
  margin: 0 0 8px 0;
  padding: 0;
  font-size: 1rem;
  color: #000;

  @media (max-width: 576px) {
    font-size: 0.9rem;
  }
`;

export const ExtendedCardGradeWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const ExtendedCardGrid = styled.div`
  display: grid;
  width: fit-content;
  gap: 1rem;
  column-gap: 0.5rem;
  grid-auto-flow: column;
`;
