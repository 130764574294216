import { IconButton, Tooltip } from "@mui/material";

export const ActionButton = ({ action, title, color, IconComponent }) => {
  return (
    action && (
      <Tooltip title={title} placement="right" arrow>
        <IconButton
          sx={{ color: color, zIndex: 2 }}
          onClick={(event) => {
            event.stopPropagation();
            action();
          }}
        >
          <IconComponent />
        </IconButton>
      </Tooltip>
    )
  );
};
