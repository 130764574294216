import { Text, View, Image } from "@react-pdf/renderer";

import logo from "../../../../../assets/logo-democrata-black.png";
import { styles } from "./styles";

export const InvoiceTitle = () => (
  <View style={styles.titleContainer}>
    <View style={styles.spaceBetween}>
      <Image style={styles.logo} src={logo} />
      <Text style={styles.reportTitle}>Express</Text>
    </View>
  </View>
);
