import React, { forwardRef, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
  Tooltip,
} from "@mui/material";

export const ProductTypeSelect = forwardRef(
  ({ productTypes, selectedType, onSelectType, ...props }, ref) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
      <FormControl
        key={`${ref}-${productTypes}-${selectedType}`}
        sx={{ minWidth: 200 }}
        size="small"
        fullWidth={isMobile}
        {...props}
      >
        <InputLabel id="demo-select-small-label">Tipo</InputLabel>

        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          label="Age"
          value={selectedType || ""}
          onChange={(e) => onSelectType(e.target.value)}
          inputRef={ref}
        >
          {productTypes.map(({ tipo: type }) => (
            <MenuItem value={type} key={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
);
