export const TOKEN_KEY = "@expressToken";
export const REMEMBER_CONTROLL = "@controlRemember";
export const USER_CONTROLL = "@control";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getControle = () => localStorage.getItem(USER_CONTROLL);
export const getRemember = () => localStorage.getItem(REMEMBER_CONTROLL);

export const login = (token, usuario, rememberMe) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(USER_CONTROLL, usuario);
  if (rememberMe) {
    localStorage.setItem(REMEMBER_CONTROLL, {
      usuario: rememberMe.usuario,
      senha: rememberMe.senha,
    });
  } else {
    localStorage.removeItem(REMEMBER_CONTROLL);
  }
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER_CONTROLL);
};

export const isAdmin = () => {
  const loggedUser = localStorage.getItem("@Express:User");

  if (loggedUser) {
    const userData = JSON.parse(loggedUser);

    return userData.privilegios?.subnvlPrivilegio === 3;
  }
  return false;
};
