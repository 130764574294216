import { blue, cyan, green, grey } from "@mui/material/colors";
import { currencyFormatter } from "../../../../../../utils/currencyFormatter";
import { Person2, Sell } from "@mui/icons-material";
import { Box, Card, Grid, Tooltip, Typography } from "@mui/material";
import { Grade } from "../../../../../../components/GradeExpress/Grade";

export const ProductCardPDF = ({ product, grade }) => {
  return (
    <>
      <Card
        sx={{
          width: "100%",
          height: "100%",
          minHeight: 130,
          display: "flex",
          flexDirection: "row",
          zIndex: 10,
        }}
      >
        {/* Container 1 */}
        <Box
          sx={{
            flex: "100%",
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Tooltip title="Clique para abrir a imagem" placement="top" arrow>
            <Box
              sx={{
                width: "100px",
                height: "100%",
                backgroundColor: "#f8f8f8",
                paddingY: 1,
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <img
                  src={`https://democrata-public-files.s3.amazonaws.com/thumbs/${
                    product.referencia
                  }.png?${new Date().getTime()}`}
                  crossOrigin="anonymous"
                  key={`${product.id}-${product.referencia}-${product.semana}`}
                  id={`${product.id}-${product.referencia}-${product.semana}`}
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: 60,
                  }}
                />
              </Box>
            </Box>
          </Tooltip>
          <Box
            width="100%"
            display="flex"
            flexDirection={"column"}
            justifyContent="center"
            ml={0.4}
            px={1}
          >
            <Box
              display="flex"
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              my={1}
              width="100%"
            >
              <Box
                width={"100%"}
                flex={1}
                display="flex"
                gap={0.5}
                maxWidth={280}
              >
                <Typography color="#000" fontWeight={600}>
                  {product.linha}
                </Typography>
                <Typography color="#000" fontWeight={600}>
                  {product.cor}
                </Typography>
              </Box>
              <Typography color={grey[600]} fontWeight={600}>
                {product.referencia}
              </Typography>
            </Box>
            <Box
              mb={1}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tooltip title="Preço de Venda" arrow>
                    <Box
                      sx={{
                        backgroundColor: blue[50],
                        borderRadius: "10px",
                        border: `1px solid ${blue[100]}`,
                        px: 1,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Person2
                        sx={{ mr: 1, color: blue[800], fontSize: "20px" }}
                      />
                      <Typography
                        color={blue[800]}
                        fontSize={16}
                        textAlign="center"
                      >
                        {currencyFormatter(
                          product.precoRepresentante?.preco ||
                            product.precoVendedor ||
                            0
                        )}
                      </Typography>
                    </Box>
                  </Tooltip>

                  {product.precoConsumidor && (
                    <Tooltip title="Preço Recomendado" arrow>
                      <Box
                        sx={{
                          backgroundColor: green[50],
                          borderRadius: "10px",
                          border: `1px solid ${green[100]}`,
                          px: 1,
                          display: "flex",
                          alignItems: "center",
                          ml: 1,
                        }}
                      >
                        <Sell
                          sx={{ mr: 1, color: cyan[900], fontSize: "20px" }}
                        />
                        <Typography
                          variant="body2"
                          fontSize={16}
                          textAlign="center"
                          color={cyan[900]}
                        >
                          {currencyFormatter(
                            product.precoConsumidor?.preco ||
                              product.precoConsumidor ||
                              0
                          )}
                        </Typography>
                      </Box>
                    </Tooltip>
                  )}
                </Box>
              </Box>
              {new Date(product.semana).toLocaleDateString("pt-BR", {
                timeZone: "UTC",
              })}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Grid
                container
                sx={{
                  minHeight: 64,
                  alignItems: "flex-end",
                }}
              >
                {grade.map((item) => (
                  <Grade
                    key={item.nro}
                    nro={item.nro}
                    estoque={item.estoque}
                    alert={item.alertStock}
                  />
                ))}
              </Grid>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="overline" mx={1}>
                Total: {product.quantidade}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
    </>
  );
};
