import React from "react";
import {
  TextField,
  InputAdornment,
  useTheme,
  useMediaQuery,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import GridViewIcon from "@mui/icons-material/GridView";
import ClearIcon from "@mui/icons-material/Clear";

export const ProductFilter = ({
  filterValue,
  onFilterChange,
  width,
  filterByProductType,
  productTypeOptions,
  onFilterByProductTypeChange,
  onClearFilter,
  disableText,
  filterFunctionOnPress,
  type,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {type === "search" && (
        <TextField
          variant="outlined"
          label="Filtrar Produtos"
          key="search-product"
          // disabled={disableText}
          placeholder="Referência, Linha, Cor ou Tipo de produto"
          sx={{
            width: width,
            mt: isMobile && 2,
          }}
          value={filterValue}
          onChange={onFilterChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              filterFunctionOnPress();
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      {type === "select" && (
        <Tooltip
          title={
            !productTypeOptions
              ? "Selecione uma data de Express "
              : "Filtrar por tipo de produto"
          }
          placement="top"
          arrow
        >
          <FormControl sx={{ minWidth: 200 }} key="search-by-type">
            <>
              <InputLabel id="express-product-types-label">
                Tipo de Produto
              </InputLabel>
              <Select
                labelId="express-product-types-label"
                id="select-product-type"
                variant="outlined"
                label="Tipo de Produto"
                size="medium"
                disabled={
                  productTypeOptions?.length === 0 || !productTypeOptions
                }
                value={filterByProductType || ""}
                onChange={onFilterByProductTypeChange}
                IconComponent={() => null}
                startAdornment={
                  <InputAdornment position="start">
                    <GridViewIcon />
                  </InputAdornment>
                }
                endAdornment={
                  filterByProductType && (
                    <InputAdornment position="end">
                      <IconButton onClick={onClearFilter}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              >
                {productTypeOptions?.map((productType) => (
                  <MenuItem key={productType} value={productType}>
                    {productType}
                  </MenuItem>
                ))}
              </Select>
            </>
          </FormControl>
        </Tooltip>
      )}
    </>
  );
};
