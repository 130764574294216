import React, { useState, useEffect, Fragment } from "react";
import api from "../../services/api";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { logout, getControle } from "../../services/auth";
import { useLoggedUser } from "../../contexts/LoggedUserContext";

/**
 * Componente criado para renderizar os detalhes dos usuario logado
 * Este compoe junto com o componente Menu exibindo a foto e outros itens
 * de informacoes do usuario
 * @returns
 */
export const UserIdent = () => {
  let history = useHistory();
  const usuarioControle = getControle();
  const [data, setData] = useState([]);

  const [uDescricao, setUDescricao] = useState([]);
  const [uEmail, setUEmail] = useState([]);
  const [uCCusto, setUCCusto] = useState([]);

  useEffect(() => {
    api.get(`/usuarioDetalhe/` + usuarioControle).then(
      (response) => {
        setUDescricao(response.data.records[0].descricao);
        setUEmail(response.data.records[0].email);
        setUCCusto(response.data.records[0].ccusto);
      },
      (error) => {
        logOff();
      }
    );
  }, []);

  useEffect(() => {
    if (usuarioControle === null) {
      logOff();
    }
  });

  const logOff = () => {
    //FUNCAO DO AUTH PADRAO
    logout();
    history.push({
      pathname: "/",
    });

    window.location.reload();
  };

  if (!data) return null;

  return (
    <>
      <div className="mt-2">
        <i className="fas fa-user mr-2"></i>
        {uDescricao}
      </div>

      <div className="mb-4">
        {uEmail && (
          <Fragment>
            <i className="fas fa-envelope mr-2"></i>
            {uEmail}
          </Fragment>
        )}
      </div>
    </>
  );
};
