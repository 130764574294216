import { Badge } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useQuery } from "react-query";
import { fetchProdutosClientes } from "../pages/Express/Checkout/services/fetchProdutosClientes";
import { useLoggedUser } from "../contexts/LoggedUserContext";

export const Cart = () => {
  const { loggedUser, currentUser } = useLoggedUser();

  const { data: cartItems } = useQuery(["checkout"], () => {
    return fetchProdutosClientes(currentUser);
  });

  const totalOrders = cartItems?.reduce((acc, item) => {
    return acc + 1;
  }, 0);

  return (
    <Badge color="error" badgeContent={totalOrders} showZero>
      <ShoppingCartIcon />
    </Badge>
  );
};
