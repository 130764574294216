import { Box, Button, Link, Typography } from "@mui/material";

import MoneyOffIcon from "@mui/icons-material/MoneyOff";

export const EmptyOrdersUI = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent={"center"}
      alignItems="center"
      height="70vh"
    >
      <MoneyOffIcon color="primary" sx={{ width: 60, height: 60 }} />
      <Typography variant="h6" color="textSecondary" mt={2}>
        Não existem pedidos concluídos até o momento.
      </Typography>

      <Link href="/lista/produtos" sx={{ textDecoration: "none" }}>
        <Button variant="contained" sx={{ mt: 4 }}>
          Criar novo pedido
        </Button>
      </Link>
    </Box>
  );
};
