import { useMutation, useQueryClient } from "react-query";
import { useLoggedUser } from "../../../../contexts/LoggedUserContext";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import api from "../../../../services/api";

export const ButtonRemoveDiscount = ({ customers }) => {
  const { loggedUser, currentUser } = useLoggedUser();
  const queryClient = useQueryClient();

  const removeDiscountForSelectedOrders = useMutation(
    async (customers) => {
      const promises = customers.map(async (temporaryOrder) => {
        const response = await api.post(`/i-checkout-tmp/aplicar-desconto`, {
          reprVendedor: currentUser,
          cliente: temporaryOrder.clientId,
          semanas: temporaryOrder.dates,
          desconto: 0,
          segDesconto: 0,
        });

        if (response.status === 200) {
          return toast.success("Desconto removido com sucesso!");
        }

        return response.data.records;
      });

      await Promise.all(promises);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("checkout");
      },
    }
  );
  return (
    <Button
      onClick={() => removeDiscountForSelectedOrders.mutate(customers)}
      disabled={
        customers.length === 0 || removeDiscountForSelectedOrders.isLoading
      }
      color="error"
      size="small"
    >
      Remover
    </Button>
  );
};
