import styled from "styled-components";
import px2vw from "../../utils/px2vw";



export const Imagem = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  
`;



