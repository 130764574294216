import { useState } from "react";
import { useProductContext } from "../contexts/ProductContext";

export const useProductCardActions = () => {
  const { selecionarProduto } = useProductContext();

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [hoveredProductId, setHoveredProductId] = useState(null);

  const handleHover = (productId) => {
    setHoveredProductId(productId);
  };

  const handleHoverLeave = () => {
    setHoveredProductId(null);
  };

  const handleSelectProducts = (selectedProduct) => {
    setSelectedProducts((prevSelected) => {
      const isSelected = prevSelected.some(
        (product) => product.id === selectedProduct.id
      );

      return isSelected
        ? prevSelected.filter((product) => product.id !== selectedProduct.id)
        : [...prevSelected, selectedProduct];
    });
  };

  const handleSelectProductsArray = (productsSet, date) => {
    const selectedProduct = productsSet.size > 0 && Array.from(productsSet)[0];
    selecionarProduto(selectedProduct);

    const updatedProducts = Array.from(productsSet).map((product) => ({
      ...product,
      dataExpress: date,
    }));

    if (updatedProducts.length > 0) {
      sessionStorage.setItem(
        "@Express:SessionProducts",
        JSON.stringify(updatedProducts)
      );
    }
  };

  return {
    handleHover,
    handleHoverLeave,
    hoveredProductId,
    handleSelectProducts,
    handleSelectProductsArray,
    selectedProducts,
  };
};
