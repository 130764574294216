import React, { Component } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Content from '../../components/Content';



class Home extends Component {


    render() {
        return (
            <div  className="wrapper">
                
                <Header/>
                
                <Content/>
                <Footer/>

                {/* <Header/>
                    <Content/>
                <Footer/> */}

            </div>
        );
    }
}

export default Home;
