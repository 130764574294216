import { useQuery } from "react-query";
import api from "../services/api";

export const useReservedAmount = (
  referencia,
  dataExpress,
  refetchOnModalState
) => {
  const fetchReservedAmount = async () => {
    const response = await api.post(`/i-checkout-tmp/itens-temporaria`, {
      codigoReferencia: referencia,
      dataExpress: dataExpress,
    });

    if (response.status !== 200) {
      return [];
    }

    return response.data;
  };

  const {
    data: reservedAmount,
    isLoading,
    refetch,
  } = useQuery(
    ["reservedAmount", referencia, dataExpress, refetchOnModalState],
    fetchReservedAmount,
    {
      staleTime: 10000,
      refetchOnWindowFocus: true,
    }
  );

  return { reservedAmount, refetch, isLoading };
};
