import React, { useRef, useMemo, useState } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useQuery } from "react-query";
import api from "../../../../../services/api";
import { useTabelasPrecoContext } from "../../../../../contexts/TabelasPreco";
import { useLoggedUser } from "../../../../../contexts/LoggedUserContext";
import { savePDF } from "@progress/kendo-react-pdf";
import { ProductCardPDF } from "./pdf/ProductCardPDF";

const chunkArray = (array, size) => {
  return array.reduce((acc, _, i) => {
    if (i % size === 0) acc.push(array.slice(i, i + size));
    return acc;
  }, []);
};

export const ProductsListModal = ({ open, onClose, dates }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { loggedUser } = useLoggedUser();
  const { selectedTabelaPreco } = useTabelasPrecoContext();
  const pdfExportComponent = useRef(null);

  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

  const document =
    loggedUser.representante || loggedUser.consultor || loggedUser.vendedor;

  const { data: products = [], isLoading } = useQuery(
    "pdf-products",
    async () => {
      const response = await api.post(
        `/listaEstoqueRepImed/semana-completo?tabelaPreco=${selectedTabelaPreco.tabelapreco}&tipoUsuario=${loggedUser.tipo}&cnpjRepresentante=${document}&size=1000`,
        {
          semanas: dates,
        }
      );

      return response.data.records;
    },
    {
      refetchOnWindowFocus: false,
      onError: () => [],
    }
  );

  const productChunks = useMemo(() => chunkArray(products, 4), [products]);

  const handleExportPDF = async () => {
    // Futuramente, implementar uma PROMISE aqui. - Rafael
    try {
      setIsGeneratingPdf(true);
      if (pdfExportComponent.current) {
        savePDF(pdfExportComponent.current, {
          paperSize: "A4",
          margin: { bottom: "50" },
          forcePageBreak: ".page-break",
          fileName: `produtos-express.pdf`,
        });
      }
    } finally {
      setIsGeneratingPdf(false);
    }
  };

  return (
    <Dialog
      open={open}
      key="pdf-modal"
      onClose={onClose}
      fullWidth
      fullScreen={isMobile}
    >
      <DialogContent sx={{ gap: 0.5, width: "100%", p: isMobile ? 0 : 2 }}>
        {isLoading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {isLoading && <CircularProgress />}
          </Box>
        )}
        <Box ref={pdfExportComponent} sx={{ marginTop: 2 }}>
          {productChunks.map((productChunk, index) => (
            <Box key={index} className="pdf-page">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  mt: 1,
                }}
              >
                {productChunk.map((product) => (
                  <ProductCardPDF
                    key={`${product.id}-${product.semana}-pdf-${index}`}
                    product={product}
                    grade={product.gradeCompleta || []}
                  />
                ))}
              </Box>
              {index < productChunks.length - 1 && (
                <div className="page-break" />
              )}
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Fechar</Button>
        <Button
          variant="contained"
          onClick={handleExportPDF}
          disabled={isLoading || isGeneratingPdf}
        >
          Gerar PDF
        </Button>
      </DialogActions>
    </Dialog>
  );
};
