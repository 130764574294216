import { Typography } from "@mui/material";
import { blue, orange, red } from "@mui/material/colors";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";

const formatTime = (time = 0) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

const CountdownTimer = ({ currentTime }) => {
  const [timer, setTimer] = useState(currentTime * 60);
  const interval = useRef();

  const startTimer = () => {
    interval.current = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 0) {
          clearInterval(interval.current);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    startTimer();

    return () => clearInterval(interval.current);
  }, []);

  return (
    <Typography
      key={`countdown-timer-${timer}`}
      variant="overline"
      color={timer === 0 ? red[500] : blue[500]}
      sx={{
        outline: "1px solid",
        borderColor: timer === 0 && red[500],
        borderRadius: "4px",
        p: 1,
      }}
    >
      {timer === 0 ? "Expirado" : formatTime(timer)}
    </Typography>
  );
};

export default CountdownTimer;
