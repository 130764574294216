import {
  Badge,
  Box,
  Button,
  Chip,
  IconButton,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { Table } from "react-bootstrap";
import { formatarData } from "../../utils/utils";
import { Edit } from "@mui/icons-material";
import { useState } from "react";

export const UserApprovalTable = ({
  users,
  roles,
  handleSelectUser,
  handleDialogIsOpen,
  page,
  setPage,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("tipo");

  const tableCells = {
    header: [
      "ID",
      "Usuário",
      "Tipo",
      "Status",
      "Data Solicitação",
      "Data Expiração",
      "Ações",
    ],
  };

  const headerToUserProperty = {
    ID: "id",
    Usuário: "codUsuario.nome",
    Tipo: "",
    Status: "status",
    "Data Solicitação": "dtSolicitacao",
    "Data Expiração": "expira",
  };

  const getNestedProperty = (obj, path) => {
    return path.split(".").reduce((o, p) => (o ? o[p] : null), obj);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (header) => (event) => {
    const property = headerToUserProperty[header];
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {tableCells.header.map((cell, index) => (
              <TableCell style={{ textAlign: "center" }} key={index}>
                <TableSortLabel
                  active={orderBy === cell}
                  direction={orderBy === cell ? order : "asc"}
                  onClick={handleSort(cell)}
                >
                  {cell}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {users
            .sort((a, b) => {
              const aValue = getNestedProperty(a, orderBy);
              const bValue = getNestedProperty(b, orderBy);
              return (aValue < bValue ? -1 : 1) * (order === "asc" ? 1 : -1);
            })
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((user) => (
              <TableRow
                key={user.id}
                sx={{
                  alignItems: "center",
                  "&:hover": {
                    backgroundColor: "#f8f8f8",
                    cursor: "pointer",
                  },
                }}
              >
                <TableCell style={{ textAlign: "center" }}>{user.id}</TableCell>

                <TableCell style={{ textAlign: "center" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="subtitle1">
                      {user.codUsuario.nome}
                    </Typography>
                  </Box>

                  {roles[user.codUsuario?.privilegios?.subnvlPrivilegio]
                    ?.name && (
                    <Chip
                      label={
                        roles[user.codUsuario?.privilegios?.subnvlPrivilegio]
                          ?.name
                      }
                      color={
                        roles[user.codUsuario?.privilegios?.subnvlPrivilegio]
                          ?.color
                      }
                    />
                  )}

                  {!roles[user.codUsuario?.privilegios?.subnvlPrivilegio]
                    ?.name && (
                    <Typography variant="body2">Não definido</Typography>
                  )}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  {roles[user.codUsuario?.tipo]?.name && (
                    <Chip
                      icon={roles[user.codUsuario?.tipo]?.icon}
                      label={roles[user.codUsuario?.tipo]?.name}
                      color={roles[user.codUsuario?.tipo]?.color || "default"}
                    />
                  )}

                  {!roles[user.codUsuario?.tipo]?.name && (
                    <Typography>
                      {roles[user.codUsuario?.tipo]?.name || "Não definido"}
                    </Typography>
                  )}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <Chip
                    label={user.status === 1 ? "Ativo" : "Inativo"}
                    color={user.status === 1 ? "success" : "error"}
                  />
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  {formatarData(user.dtSolicitacao)}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  {formatarData(user.expira)}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <IconButton
                    sx={{ backgroundColor: "#f8f8f8" }}
                    onClick={() => {
                      handleSelectUser(user);
                      handleDialogIsOpen();
                    }}
                  >
                    <Edit color="warning" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={users.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Usuários por página"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
        }
      />
    </TableContainer>
  );
};
