import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";

export const DialogConfirmation = ({
  children,
  open,
  onClose,
  onConfirm,
  isLoading = false,
  fullWidth = true,
  closeButton,
  title,
  imageDialog,
  disableActionsButtons,
  isRunningRequest,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={isMobile}
      fullWidth={fullWidth}
    >
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: title ? "space-between" : "end",
              alignItems: "center",
              userSelect: "none",
            }}
          >
            <Typography variant="overline" fontSize={14} fontWeight={500}>
              {title}
            </Typography>
            {closeButton && (
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            )}
          </DialogTitle>
          <DialogContent>{children}</DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                width: fullWidth ? "100%" : null,
                flexDirection: isMobile ? "column" : "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 1,
              }}
            >
              {!disableActionsButtons && !imageDialog && (
                <>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={onClose}
                    sx={{
                      "&.MuiButton-outlinedSecondary": {
                        border: `1px solid ${grey[400]}`,
                      },
                      color: grey[700],
                    }}
                    fullWidth
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    fullWidth
                    onClick={onConfirm}
                    disabled={isRunningRequest}
                  >
                    Confirmar
                  </Button>
                </>
              )}
            </Box>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
