import React, { createContext, useReducer, useMemo, useContext } from "react";

const CheckoutContext = createContext(null);

const initialState = {
  selectedDates: [],
  selectedCondPgto: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_SELECTED_DATES":
      return { ...state, selectedDates: action.payload };
    case "SET_SELECTED_CONDPGTO":
      return { ...state, selectedCondPgto: action.payload };
    default:
      return state;
  }
};

export const useCheckout = () => useContext(CheckoutContext);

export const CheckoutProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(
    () => ({
      ...state,
      setSelectedDates: (dates) =>
        dispatch({ type: "SET_SELECTED_DATES", payload: dates }),
      setSelectedCondPgto: (condPgto) =>
        dispatch({ type: "SET_SELECTED_CONDPGTO", payload: condPgto }),
    }),
    [state]
  );

  return (
    <CheckoutContext.Provider value={value}>
      {children}
    </CheckoutContext.Provider>
  );
};
