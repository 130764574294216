import { useContext } from "react";
import { useState } from "react";
import { createContext } from "react";
import { ProductContextWrapper } from "./ProductContext";

export const ClienteContext = createContext({});

export const ClienteContextWrapper = ({ children }) => {
  const [cliente, setCliente] = useState({});

  const selecionarCliente = (cliente) => {
    setCliente(cliente);

    localStorage.setItem("@CLIENTE", JSON.stringify(cliente));
  };

  return (
    <ClienteContext.Provider
      value={{
        cliente,
        selecionarCliente,
      }}
    >
      {children}
    </ClienteContext.Provider>
  );
};

export const useClienteContext = () => {
  return useContext(ClienteContext);
};
