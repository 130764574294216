import {
  Checkbox,
  Typography,
  Box,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { ProductEntry } from "./ProductEntry";
import { blue, cyan, grey, orange, red } from "@mui/material/colors";
import { OrderSummary } from "./OrderSummary";
import CountdownTimer from "./CountdownTimer";
import { useBreakpoints } from "../../../../hooks/useBreakpoints";
import { CalendarMonth } from "@mui/icons-material";

export const ProductDateEntry = ({
  entrega,
  produtos,
  produtosCliente,
  handleProductCheck,
  calculateOrderSummary,
  selectedDates,
  clearSelectedDates,
}) => {
  const isMobile = useBreakpoints("md");

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.only("sm"));

  const currentOrderSummary = calculateOrderSummary(produtos);

  const updatedProducts = produtos.map((produto) => {
    return {
      ...produto,
      gradeProduto: produto.gradeProduto.filter((grade) => grade.estoque > 0),
      gradePadrao: [],
    };
  });

  const isDateSelected = selectedDates.some(
    (entry) =>
      entry.dates.includes(entrega) &&
      entry.clientId === produtosCliente.cliente.cnpjCpf
  );

  const customerInfo = produtosCliente.cliente;

  return (
    <Box my={4}>
      <Box
        display="grid"
        gridTemplateColumns={isMobile ? "1fr 1fr" : "1fr 1fr 1fr"}
        gridTemplateAreas={
          isMobile
            ? `
          "date button"

          `
            : `
          "date . button"
          `
        }
        alignItems={"center"}
        sx={{
          borderRadius: "5px",

          py: 1,
          pl: 1,
          boxShadow: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gridArea: "date",
          }}
        >
          <CalendarMonth sx={{ marginRight: 1, color: grey[700] }} />
          <Typography color={grey[700]} fontWeight={600}>
            {entrega}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gridArea: "button",
          }}
        >
          <Button
            onClick={() =>
              handleProductCheck(
                entrega,
                produtosCliente.cliente.cnpjCpf,
                produtosCliente.cliente.razao,
                produtosCliente.tabelaPreco,
                produtosCliente.itens[0].desconto > 0
              )
            }
            startIcon={<Checkbox checked={isDateSelected} />}
          >
            Marcar Pedido
          </Button>
        </Box>
      </Box>

      <Box
        my={2}
        sx={{
          display: "grid",
          gridTemplateColumns: isMediumScreen ? "1fr 1fr" : "1fr",
          gap: 1,
        }}
      >
        {updatedProducts.map((produto, idx) => (
          <ProductEntry
            orderDate={entrega}
            product={produto}
            customerData={produtosCliente}
            key={produto.id}
          />
        ))}
      </Box>

      <OrderSummary
        order={currentOrderSummary}
        customer={customerInfo}
        expressDate={entrega}
        clearSelectedDates={clearSelectedDates}
      />
    </Box>
  );
};
