import { PedidosList } from "./Components/PedidosList";
import { DefaultLayout } from "../../../layouts/DefaultLayout";

const PedidosRealizados = () => {
  const breadcrumbs = [
    {
      url: "/pedidos-realizados",
      label: "Pedidos Realizados",
    },
  ];

  return (
    <DefaultLayout breadcrumbs={breadcrumbs}>
      <PedidosList />
    </DefaultLayout>
  );
};

export default PedidosRealizados;
