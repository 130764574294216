import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useProductCardActions } from "../../../../../hooks/useProductCardActions";
import { useSelectedProductsContext } from "../../../../../contexts/SelectedProductsContext";

export const ItemsCountBar = () => {
  const { selectedProducts } = useSelectedProductsContext();

  const { handleSelectProductsArray } = useProductCardActions();

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "background.paper",
        padding: 1,
        display: "flex",
        justifyContent: "center",

        alignItems: "center",

        zIndex: 1000,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
        className="container"
      >
        <Typography
          variant="overline"
          fontSize={16}
          color="textSecondary"
          ml={2}
        >
          itens: {selectedProducts.size}
        </Typography>

        <Link to={`/clientes`}>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            onClick={() => handleSelectProductsArray(selectedProducts)}
            sx={{
              marginRight: 2,
              borderRadius: 1,
              textTransform: "none",
            }}
          >
            Avançar
          </Button>
        </Link>
      </Box>
    </Box>
  );
};
