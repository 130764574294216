import { Box, Button, Typography } from "@mui/material";

import api from "../../../../services/api";

import { ProductCardDefault } from "../../../../components/GradeExpress/Standard/ProductCardDefault";
import { useBreakpoints } from "../../../../hooks/useBreakpoints";
import { useState } from "react";
import { DialogGrade } from "./DialogGrade";
import {
  productWithGrade,
  productWithReservado,
} from "../../../../utils/productWithGrade";
import { useProductContext } from "../../../../contexts/ProductContext";
import { useReservedAmount } from "../../../../hooks/useReservedAmount";
import { green, grey } from "@mui/material/colors";
import CountdownTimer from "./CountdownTimer";
import { DialogConfirmation } from "../../../../components/DialogConfirmation";
import { useTabelasPrecoContext } from "../../../../contexts/TabelasPreco";
import { currencyFormatter } from "../../../../utils/currencyFormatter";
import { toast } from "react-toastify";
import { isMultipleOfBaseMultiples } from "../../../../utils/isMultipleOfBaseMultiples";
import { useLoggedUser } from "../../../../contexts/LoggedUserContext";
import { useMutation, useQueryClient } from "react-query";

export const ProductEntry = ({ product, orderDate, customerData }) => {
  const queryClient = useQueryClient();

  const { selectedTabelaPreco } = useTabelasPrecoContext();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [removeDialogIsOpen, setRemoveDialogIsOpen] = useState(false);
  const [isRemovingProduct, setIsRemovingProduct] = useState(false);

  const { produtoSelecionado, selecionarProduto, gradeSelecionada } =
    useProductContext();
  const [isLoading, setIsLoading] = useState(true);

  const isMobile = useBreakpoints("md");

  const { loggedUser, currentUser } = useLoggedUser();

  const { reservedAmount } = useReservedAmount(
    product.referencia,
    orderDate,
    modalIsOpen
  );

  const fetchProductStock = async (productObject) => {
    setIsLoading(true);

    const response = await api.post(
      `listaEstoqueRepImed/semana/referencia?referencia=${productObject.referencia}&tipoUsuario=${loggedUser.tipo}&tabelaPreco=${selectedTabelaPreco.tabelapreco}`,
      {
        semanas: [productObject.entrega],
      }
    );

    if (response.data.records.length === 0) {
      return `Produto não disponível para a tabela de preço selecionada: ${selectedTabelaPreco.tabelapreco}`;
    }

    const updatedProduct = productWithGrade(
      response.data.records,
      productObject.entrega
    );

    const updatedProductWithGradePadrao = {
      ...updatedProduct[0],
      gradePadrao: productObject.gradeProduto || [],
      reservado: productWithReservado(productObject, reservedAmount) || [],
      reprVendedor: productObject.reprVendedor,
    };

    selecionarProduto(updatedProductWithGradePadrao);

    setIsLoading(false);
  };

  const removeProductMutation = useMutation(
    async (item) => {
      setIsRemovingProduct(true);
      const response = await api.delete(`/i-checkout-tmp/${item.id}`);

      return response;
    },
    {
      onSuccess: () => {
        setRemoveDialogIsOpen(false);
        queryClient.invalidateQueries("checkout");
      },
    }
  );

  const handleOpenModal = (product) => {
    selecionarProduto(product);

    fetchProductStock(product);

    setModalIsOpen(!modalIsOpen);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleRemoveDialogIsOpen = () => {
    setRemoveDialogIsOpen(!removeDialogIsOpen);
  };

  const updateProductMutation = useMutation(
    async ({ updatedProduct, dadosJson }) => {
      const response = await api.put(
        `/i-checkout-tmp/${updatedProduct.id}`,
        dadosJson
      );
      return response;
    },
    {
      onSuccess: () => {
        handleCloseModal();
        queryClient.invalidateQueries("checkout");
      },
    }
  );

  const handleConfirm = async () => {
    const updatedProduct = {
      ...product,
      pedido: gradeSelecionada,
    };

    const totalQuantityInGrade = updatedProduct.pedido.reduce(
      (acc, grade) => acc + grade.estoque,
      0
    );

    const isNumberMultiple = isMultipleOfBaseMultiples(totalQuantityInGrade);

    if (!isNumberMultiple && product.linha !== "MOCHILA") {
      return toast.error(
        "A quantidade total da grade deve ser múltipla de 8, 10, 12, 15 ou 18."
      );
    }

    const quants = {};

    updatedProduct.pedido.forEach((gradeItem) => {
      const gradeKey = Object.keys(product.grade).find(
        (key) => product.grade[key] === gradeItem.nro.toString()
      );
      if (gradeKey) {
        const quantNumber = gradeKey.replace("nro", "");
        quants[`quant${quantNumber}`] = gradeItem.estoque;
      }
    });

    const totalQuant = Object.values(quants).reduce(
      (acc, curr) => acc + curr,
      0
    );

    const dadosJson = {
      reprVendedor: currentUser,
      cliente: customerData.cliente.cnpjCpf,
      preco: updatedProduct.preco,
      unitario: updatedProduct.unitario,
      referencia: updatedProduct.referencia,
      conceito: updatedProduct.conceito,
      linha: updatedProduct.linha,
      cor: updatedProduct.cor,
      entrega: updatedProduct.entrega,
      quantidade: totalQuant,
      grade: { grade: updatedProduct.grade.grade },
      ...quants,
    };

    return updateProductMutation.mutate({ updatedProduct, dadosJson });
  };

  return (
    <Box mb={1}>
      <ProductCardDefault
        product={product}
        grade={product.gradeProduto}
        actions={{
          handleAddGrade: () => {
            handleOpenModal(product);
          },
          edit: () => {
            handleOpenModal(product);
          },
        }}
        variant="extended"
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: !isMobile && "100%",
            justifyContent: isMobile ? "flex-start" : "baseline",
            alignItems: "end",
            mb: isMobile && 2,
            mt: isMobile && 1,
          }}
        ></Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateAreas: isMobile
              ? `'price countdown countdown'
         'remove remove remove'`
              : `'price price price'
         'countdown . remove'`,
            gap: 1,
            justifyContent: "space-between",
            flexDirection: "column",
            m: 1,
          }}
        >
          <Box
            gridArea={"price"}
            alignSelf={"start"}
            sx={{
              display: "flex",
              flexDirection: !isMobile && "column",
              alignItems: isMobile && "center",
            }}
          >
            {product.desconto > 0 && (
              <Typography
                fontSize={isMobile ? 18 : 16}
                color={grey[800]}
                sx={{ textDecoration: "line-through" }}
                textAlign={"end"}
              >
                {currencyFormatter(product.preco)}
              </Typography>
            )}
            {product.unitario && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {product.desconto > 0 && (
                  <Typography
                    variant="overline"
                    mr={2}
                    ml={isMobile && 2}
                    color={green[500]}
                  >
                    (-{product.desconto.toFixed(2)}%)
                  </Typography>
                )}
                <Typography
                  fontSize={isMobile ? 18 : 16}
                  color={grey[800]}
                  textAlign={"end"}
                  fontWeight={600}
                >
                  {currencyFormatter(product.unitario || 0)}
                </Typography>
              </Box>
            )}
          </Box>

          <Box
            gridArea={"countdown"}
            alignSelf={"end"}
            textAlign={isMobile && "end"}
          >
            <CountdownTimer currentTime={product.duracao} />
          </Box>

          <Box gridArea={"remove"} alignSelf={"end"}>
            <Button
              size="small"
              variant="contained"
              color="error"
              fullWidth={isMobile}
              sx={{ mt: isMobile && 2 }}
              onClick={handleRemoveDialogIsOpen}
            >
              Remover
            </Button>
          </Box>
        </Box>
      </ProductCardDefault>
      {modalIsOpen && (
        <DialogGrade
          modal={{
            modalIsOpen,
            handleCloseModal,
            handleConfirm,
            isLoading,
          }}
          product={produtoSelecionado}
        />
      )}

      {removeDialogIsOpen && (
        <DialogConfirmation
          open={removeDialogIsOpen}
          onClose={handleRemoveDialogIsOpen}
          onConfirm={() => removeProductMutation.mutate(product)}
          isRunningRequest={isRemovingProduct}
          title="Remover item"
        >
          <Typography variant="subtitle1" fontWeight={"bold"}>
            Tem certeza que deseja remover este item?
          </Typography>
        </DialogConfirmation>
      )}
    </Box>
  );
};
