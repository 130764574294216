import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { green, grey } from "@mui/material/colors";
import { documentMask } from "../../../../utils/documentMask";
import { AttachMoney, Download } from "@mui/icons-material";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { InvoicePDF } from "./PDF/Invoice";
import api from "../../../../services/api";
import {
  productObjectPDF,
  productWithGrade,
  productWithGradeCheckout,
} from "../../../../utils/productWithGrade";
import { useQuery } from "react-query";
import { formatarData } from "../../../../utils/utils";

export const CardOrder = ({ order, openModal }) => {
  const fetchOrderItems = async ({ queryKey }) => {
    const [, orderId] = queryKey;
    try {
      const response = await api.get(`/checkout/itens/${orderId}`);
      const productWithFormattedGrade = productObjectPDF(
        response.data.records,
        true
      );
      return productWithFormattedGrade;
    } catch (error) {
      console.error(error);
    }
  };

  const { data: products, isLoading } = useQuery(
    ["orderItems", order.id],
    fetchOrderItems,
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return;
  }

  const orderStatus = {
    S: { status: "Processado", color: "success" },
    N: { status: "Não Processado", color: "error" },
  };

  return (
    <Card sx={{ minHeight: 180 }}>
      <CardContent>
        <Box
          sx={{
            minHeight: 100,
            maxHeight: 100,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography
              sx={{
                fontSize: 16,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: 150,
              }}
              variant="h5"
              color={grey[800]}
            >
              {order.fantasia || order.razaoCliente}
            </Typography>
            <Typography
              sx={{ fontSize: 14, maxWidth: "60%" }}
              variant="subtitle2"
              color={grey[800]}
            >
              {order.razaoCliente || order.fantasia}
            </Typography>
            <Typography sx={{ fontSize: 14 }} color={grey[600]}>
              CNPJ: {documentMask(order.clientes.cnpjCpf)}
            </Typography>
          </Box>
          <Chip
            label={orderStatus[order.processado].status}
            color={orderStatus[order.processado].color}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6">{order.pedidoOriginal}</Typography>
          <Tooltip title="Data de Faturamento" arrow>
            <Typography variant="subtitle1" color={grey[700]}>
              {new Date(order.dataFaturamento).toLocaleDateString("pt-BR", {
                timeZone: "UTC",
              })}
            </Typography>
          </Tooltip>
        </Box>
      </CardContent>
      <CardActions>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <PDFDownloadLink
            document={<InvoicePDF order={order} products={products} />}
            fileName={`${order.id}-${order.clientes.razao}-${formatarData(
              order.dataFaturamento
            )}.pdf`}
          >
            {({ blob, url, loading, error }) => (
              <Tooltip title="Download em PDF" arrow>
                <IconButton disabled={loading} color="primary">
                  <Download />
                </IconButton>
              </Tooltip>
            )}
          </PDFDownloadLink>
          <Button onClick={openModal} size="small">
            Visualizar Itens
          </Button>
          {/* <Button variant="contained" size="small" color="primary">
          Replicar Pedido
        </Button> */}
        </Box>
      </CardActions>
    </Card>
  );
};
